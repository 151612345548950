const ReservationPage = () => {
  return (
    <>
      <div class="dashboard-container">
        {/* <!-- Live Activity -->
    <!-- <div class="live-activity">Live Activity</div> --> */}

        {/* <!-- Stats Section --> */}
        <div class="Reservations-section">
          <div class="row d-flex align-items-center">
            <div class="col-xxl-8 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div>
                <div>Filters </div>
                <div class="d-flex flex-wrap">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      Checking in
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Checking out
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Booked on
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Cancelled on
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="d-flex gap-3 justify-content-end">
                <div class="input-group input-group-text-basic input-group-ui">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for something"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                  />
                </div>

                <div>
                  <button
                    type="button"
                    class="btn btn-primary search-btn-primary"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-end mt-5">
            <div class="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12">
              <div class="row align-items-end">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label for="inputEmail4" class="form-label">
                    From
                  </label>
                  {/* <!-- <input type="date" id="birthday" name="birthday"> --> */}
                  <input
                    type="date"
                    class="form-control"
                    id="date"
                    placeholder=""
                    aria-label=""
                  />
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <label for="inputEmail4" class="form-label">
                      To
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      id="date"
                      placeholder=""
                      aria-label=""
                    />
                  </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="bi-arrow-apply">
                    <i class="bi bi-arrow-counterclockwise bi-arrow-counterclockwise-icon"></i>
                    <button type="button" class="btn btn-primary apply-btn">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 d-flex justify-content-end">
              {/* <!-- <button type="button" class="btn btn-outline-primary more-filters-btn">More filters <i
                        class="bi bi-filter"></i></button> --> */}
              {/* <!-- Button trigger modal --> */}
              <button
                type="button"
                class="btn btn-outline-primary more-filters-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalmore"
              >
                More filters<i class="bi bi-filter"></i>
              </button>

              {/* <!-- Modal --> */}
              <div
                class="modal fade"
                id="exampleModalmore"
                tabindex="-1"
                aria-labelledby="exampleModalLabel-more"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-sm">
                  <div class="modal-content">
                    {/* <!-- <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabelmore">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div> --> */}
                    <div class="modal-body mt-4 mb-3 px-4">
                      <div class="payment-text-modal">
                        <h6>Reservation </h6>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Booked </span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Cancelled</span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Confirm</span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Special request</span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Unconfirmed</span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>VIP </span>
                          </label>
                        </div>
                      </div>
                      <div class="payment-text-modal mt-4 mb-2">
                        <h6>Payment </h6>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span> GMH Collect Payment </span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span> Virtual Card </span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Hotel Collect Payment</span>
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            <span>Reconciled</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary clear-btn-modal"
                        data-bs-dismiss="modal"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary apply-btn-modal"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-arrivals px-3">
        <div class="calendar-header-detail">
          <div>
            <button
              class="btn btn-light prevMonth-btn-pre"
              onclick="prevMonth()"
            >
              &lt;
            </button>
          </div>
          <div>
            <h2 id="month-year" class="month-year-text">
              Today, 8 October 2024
            </h2>
          </div>
          <div>
            <button
              class="btn btn-light prevMonth-btn-pre"
              onclick="nextMonth()"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <div class="card-tab-pan mt-5">
        <div class="card-body px-4">
          {/* <!-- Nav Tabs --> */}
          <ul class="nav nav-tabs nav-tabs-al" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="arrivals-tab"
                data-bs-toggle="tab"
                data-bs-target="#arrivals"
                type="button"
                role="tab"
                aria-controls="arrivals"
                aria-selected="true"
              >
                Arrivals <span>16 </span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="departures-tab"
                data-bs-toggle="tab"
                data-bs-target="#departures"
                type="button"
                role="tab"
                aria-controls="departures"
                aria-selected="false"
              >
                Date <span>19 </span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="cancellations-tab"
                data-bs-toggle="tab"
                data-bs-target="#cancellations"
                type="button"
                role="tab"
                aria-controls="cancellations"
                aria-selected="false"
              >
                Departures <span>20 </span>
              </button>
            </li>
          </ul>

          {/* <!-- Tab Content --> */}
          <div class="tab-content" id="myTabContent">
            {/* <!-- Arrivals Tab --> */}
            <div
              class="tab-pane fade show active"
              id="arrivals"
              role="tabpanel"
              aria-labelledby="arrivals-tab"
            >
              <table class="table table-striped table-bordered mt-3">
                <thead class="table-header-th">
                  <tr>
                    <th class="Guest-th">Guest</th>
                    <th>Reservation</th>
                    <th>Confirmation</th>
                    <th>Check-in</th>
                    <th>Check-out</th>
                    <th>Room</th>
                    <th>Booked on</th>
                    <th>Booking Amount</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tbody class="table-td-header">
                  <tr>
                    <td>Mukesh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Full Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00 <br />
                      <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Suresh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Full Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00
                      <br /> <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Hitesh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Std. Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00
                      <br /> <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Mahesh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Queen Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00 <br />
                      <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Dipesh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Std. Double Bed</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00
                      <br /> <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Rakesh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Full Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00 <br />
                      <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                  <tr>
                    <td>Naresh Kumar</td>
                    <td>
                      3247500892 <br />{" "}
                      <span class="recent-btn">
                        <a href="">Recent </a>
                      </span>
                    </td>
                    <td>
                      <span>
                        <i class="bi bi-pencil-fill"></i>{" "}
                      </span>
                      24524173
                    </td>
                    <td>08-Oct-2024</td>
                    <td>10-Oct-2024</td>
                    <td>Full Room</td>
                    <td>06-oct-2024</td>
                    <td>
                      USD 450.00 <br />{" "}
                      <span class="hotel-collect">Hotel Collect </span>
                    </td>
                    <td>USD 50.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <!-- Departures Tab --> */}
            <div
              class="tab-pane fade"
              id="departures"
              role="tabpanel"
              aria-labelledby="departures-tab"
            >
              <p>No departures listed.</p>
            </div>

            {/* <!-- Cancellations Tab --> */}
            <div
              class="tab-pane fade"
              id="cancellations"
              role="tabpanel"
              aria-labelledby="cancellations-tab"
            >
              <p>No cancellations listed.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationPage;

import { useEffect, useState } from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

const DefaultCancellationPolicy = () => {
  const [isActive, isSetActive] = useState("24-hour cancellation window");
  return (
    <Card sx={{ ml: '5px', padding: '16px' }}>
      <Typography level="h3" sx={{ fontWeight: "600" }}>
        {"Default cancellation policy"}
      </Typography>
      <Typography variant="p">
        {
          "Let us know which policy you use most often. This will be your default while you’re getting your property set up. You’ll be able to add new policies or modify the policies for specific rooms or units later on."
        }
      </Typography>
      <Typography level="h3" sx={{ fontWeight: "600" }}>
        {"Cancellation policy options"}
      </Typography>
      <Typography variant="p">
        {
          "A cancellation window is the amount of time before your local cancellation cut-off (18:00) on the day of check-in."
        }
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={`24-hour cancellation window`}
          name="regulatory_information"
        >
          <FormControlLabel
            value="24-hour cancellation window"
            control={<Radio />}
          onClick={() => isSetActive("24-hour cancellation window")}

            label="24-hour cancellation window"
          />
          {isActive === "24-hour cancellation window" && (
            <>
              <ListItem>
                {
                  "Travellers who cancel 24 hours or more before 18:00 on the day of check-in are charged no fee."
                }
              </ListItem>
              <ListItem>
                {
                  "Travellers who cancel less than 24 hours before 18:00 on the day of check-in (including no-shows) are charged"
                }
              </ListItem>
              <Select defaultValue={"1st night + tax"}>
                <MenuItem value="1st night + tax">{"1st night + tax"}</MenuItem>
                <MenuItem value="50% of booking amount">
                  {"50% of booking amount"}
                </MenuItem>
                <MenuItem value="100% of booking amount">
                  {"100% of booking amounts"}
                </MenuItem>
              </Select>
            </>
          )}
          <FormControlLabel
            value="48-hour cancellation window"
            control={<Radio />}
            onClick={() => isSetActive("48-hour cancellation window")}
            label="48-hour cancellation window"
          />
          {isActive === "48-hour cancellation window" && (
            <>
              <ListItem>
                {
                  "Travellers who cancel 48 hours or more before 18:00 on the day of check-in are charged no fee."
                }
              </ListItem>
              <ListItem>
                {
                  "Travellers who cancel less than 48 hours before 18:00 on the day of check-in (including no-shows) are charged:"
                }
              </ListItem>
              <Select value={"1st night + tax"}>
                <MenuItem value="1st night + tax">{"1st night + tax"}</MenuItem>
                <MenuItem value="50% of booking   ">
                  {"50% of booking amount"}
                </MenuItem>
                <MenuItem value="100% of booking amount">
                  {"100% of booking amounts"}
                </MenuItem>
              </Select>
            </>
          )}

          <FormControlLabel
            value="72-hour cancellation window"
            control={<Radio />}
            onClick={() => isSetActive("72-hour cancellation window")}
            label="72-hour cancellation window"
          />

          {isActive === "72-hour cancellation window" && (
            <>
              <ListItem>
                {
                  "Travellers who cancel 72 hours or more before 18:00 on the day of check-in are charged no fee."
                }
              </ListItem>
              <ListItem>
                {
                  "Travellers who cancel less than 72 hours before 18:00 on the day of check-in (including no-shows) are charged:"
                }
              </ListItem>
              <Select defaultValue={"1st night + tax"}>
                <MenuItem value="1st night + tax">{"1st night + tax"}</MenuItem>
                <MenuItem value="50% of booking amount">
                  {"50% of booking amount"}
                </MenuItem>
                <MenuItem value="100% of booking amount">
                  {"100% of booking amounts"}
                </MenuItem>
              </Select>
            </>
          )}

          <FormControlLabel
            value="Non-refundable"
            control={<Radio />}
            onClick={() => isSetActive("Non-refundable")}
            label="Non-refundable"
          />
          {isActive === "Non-refundable" && <ListItem>
            {
              "Travellers who cancel at any time (including no-shows) are charged 100% of the booking amount."
            }
          </ListItem>}
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

export default DefaultCancellationPolicy;

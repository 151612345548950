import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const Main = () => {
  return (
    <footer class="footer-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-4 col-md-6 col-sm-12">
          <div class="social-card">
            <img src={`${apiUrl}/assets/images/logo-get.svg`} alt="logo-get" class="logo-home"/>
            <p>Experience Hospitality at its Finest with Get My Hotel. Book Your Stay Today and Cretae Unforgettable
              Memories.</p>
            <div class="social-icon-footer">
              <div><span> <i class="bi bi-linkedin"></i> </span></div>
              <div> <span> <i class="bi bi-facebook"></i> </span></div>
              <div> <span> <i class="bi bi-twitter"></i> </span> </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
          <div class="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Explore</a></li>
              <li><a href="#">Rooms</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Contact us</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
          <div class="footer-links">
            <h4>Information</h4>
            <ul>
              <li><a href="#">+1 (7635) 547-12-97</a></li>
              <li><a href="#">Getmyhotel@gmail.com</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
          <div class="footer-links">
            <h4>Subscribe</h4>
            <div>

              <div class="input-group px-2">
                <input type="email" class="input" id="Email" name="Email" placeholder="Email address"
                  autocomplete="off"/>
                <span class="input-icon-footer"> <input class="button--submit" value="" type="submit"/> <span
                    class="bi-bi-arrow-footer"><i class="bi bi-arrow-right-short bi-arrow-short-search"></i> </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default Main;

import React from "react";
import Location from "./Location";
import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;
const Header = ({location}) => {
  const navigate = useNavigate();
  return (
    <>
    <header>
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
          <a class="navbar-brand" href="/"><img src={`${apiUrl}/assets/images/logo-get.svg`} alt="logo-get" class="logo-home"/></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav navbar-nav-link">
              <li class="nav-item">
                <a class="nav-link nav-link-active active" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Blog</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Travls</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" tabindex="-1">Contact</a>
                
              </li>
            </ul>
            <div class="additional-info d-flex align-items-center gap-4">
              <div class="language-wrap">
                <img src={`${apiUrl}/assets/images/Group 1.svg`} class="icon-header-al"/> <span>English</span>
              </div>
              <div class="border-left property-wrap text-p">
                <p>List Your Proerty</p>
              </div>
              <div class="signin-btn">
                <button class="btn-al primary-btn" onClick={()=>navigate('/signin')}>Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
  {location && <Location/>}</>
  );
};

export default Header;

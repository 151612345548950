import { Box, Button, Typography } from "@mui/material";
import { useUploadPhotoMutation } from "../../Api/hotelApi";
import { Form } from "react-router-dom";

const MultipleImageBox = ({ setImages, images, label, name, id, common }) => {


  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: file, // Create a URL for preview
    }));
    if (common) {
      setImages(newImages);
    } else {
      // let formData = new FormData();
      // formData.append("images",formData)
      // uploadPhoto()
      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };
  const removeImage = (index) => {
    if (common) {
      const updatedImages = images.filter((_, i) => i != index);
      setImages(updatedImages);
    } else {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index)); 
    }
  };
  return (
    <Box>
      <Typography sx={{ color: "black", fontWeight: "600" }}>
        {label}
      </Typography>
      <Typography
        component={"label"}
        pl={"49px"}
        pr={"49px"}
        mt={"2px"}
        ml={"143px"}
        sx={{
          color: "#2667b9",
          border: "1px solid",
          background: "#edf7ff",
        }}
        htmlFor={id}
      >
        {"Add photos"}
      </Typography>
      <input
        type="file"
        id={id}
        name={name}
        multiple
        onChange={handleImageChange}
        accept="image/*"
        style={{ display: "none" }}
      />
      <br></br>
      {images.length > 0 && (
        <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: "100px",
                height: "auto",
                border: "1px solid #ddd",
                borderRadius: "8px",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <img
                src={URL.createObjectURL(image.preview)}
                alt={`Preview ${index + 1}`}
                style={{ width: "100%", height: "auto" }}
              />
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{ position: "absolute", top: 8, right: 8 }}
                onClick={() => removeImage(index)}
              >
                {"Remove"}
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MultipleImageBox;

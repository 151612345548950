import React from "react";

const CopyRight = () => {
  return (
  <footer class="copy-right">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="copy-right-text">
            <p> Copyright © 2024 Get My Hotels - All Rights Reserved. </p>
          </div>
        </div>
      </div>
      </div>
  </footer>
  );
};

export default CopyRight;

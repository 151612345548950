import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const SecondSuggetion = () => {
  return (
    <section class="suggestions-discovery">
    <div class="container">
      <div class="header-content">
        <h3 class="common-heading text-center">Explore Stays in Trending Destinations</h3>
      </div>
      <div class=" body-carousel">
        <div class="col-md-12">
          <div id="trendingdestination" class="owl-carousel">
            <div class="post-slide">
              <img src="./assets/images/destination-one.png"/>
              <div class="slider-destination">
                <span>Blavand</span>
              </div>
            </div>

            <div class="post-slide">
              <img src="./assets/images/destination-two.png"/>
              <div class="slider-destination">
                <span>Allinge</span>
              </div>
            </div>

            <div class="post-slide">
              <img src="./assets/images/destination-three.png"/>
              <div class="slider-destination">
                <span>New york</span>
              </div>
            </div>
            <div class="post-slide">
              <img src="./assets/images/destination-four.png"/>
              <div class="slider-destination">
                <span>Mumbai</span>
              </div>
            </div>

            <div class="post-slide">
              <img src="./assets/images/destination-two.png"/>
              <div class="slider-destination">
                <span>Allinge</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SecondSuggetion;

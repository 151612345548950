import React from "react";
import Adds from "./Adds";
import { useParams } from "react-router-dom";
import Search from "./Search";
import BestOffer from "./BestOffer";
import Discover from "./Dicover";
import SecondSuggetion from "./SecondSuggetion";
import BestFacalities from "./BestFacalities";
import MemoriableSection from "./MemoriableSection";
import DesireHotel from "./DesireHotel";
import SuggestionsDiscovery from "./SuggestionsDiscovery";

const Home = () => {
  const { state } = useParams();
  return (<>
  <DesireHotel/>
  <SuggestionsDiscovery/>
  <MemoriableSection/>
  <BestFacalities/>
  <SecondSuggetion/>
  <Discover/>
  <BestOffer/>
  </>);
};

export default Home;

import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";

const MultipleCheckboxComponent = ({ gap, option, optionLabel, optionValue, onClick, optionName, label, errors, name }) => {
    return (
        <Box gap={gap}>
            <Typography>{label}</Typography>
            <FormGroup>
                {option && option.length > 0 && option.map((item) => {
                    return <FormControlLabel control={<Checkbox defaultChecked={item.checked} />} label={item[optionLabel]} value={item[optionValue]} name={`${optionName}${[]}`} onClick={onClick} />
                })}
            </FormGroup>
            {(errors && errors[name]) && <Typography sx={{color: "red"}}>{errors[name]}</Typography>}
        </Box>
    )
}

export default MultipleCheckboxComponent;
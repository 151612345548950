import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null,
}
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
      setAuth: (state, action) => {
        state.user = action.payload
      },
      setToken: (state, action) =>{
        console.log("action-->", action.payload)
        state.token = action.payload
      }
    },
  });

export const { setAuth, setToken } = authSlice.actions;  

export default authSlice.reducer;



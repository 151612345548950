import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import DepositDetailComponent from "./depositDetailComponent";

const DepositCheckbox = ({ style, requireAnyDeposits }) => {
    const [selectedValues, setSelectedValues] = React.useState([]);

    const depositCheckBoxHandle = (value, checked) => {
        setSelectedValues((prev) =>
            checked ? [...prev, value] : prev.filter((v) => v !== value)
        );
    };

    return (
        <div style={style.depositCheckbox}>
            {requireAnyDeposits &&
                requireAnyDeposits.length > 0 &&
                requireAnyDeposits.map((item, key) => (
                    <div key={item.value}>
                        {console.log("item", item)
                        }
                        <FormControlLabel
                            label={item.label}
                            control={
                                <Checkbox
                                    name="require_any_deposit"
                                    value={item.name}
                                    // checked={selectedValues.includes(item.name)}
                                    onChange={(e) => {console.log("item.ischecked", item.ischecked);   item.ischecked = e.target.checked; depositCheckBoxHandle(item.value, e.target.checked)}}
                                />
                            }
                        />
                        <div>
                        {item.ischecked && item.data && (
                            <DepositDetailComponent option={item.data} key={key} />
                        )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default DepositCheckbox;

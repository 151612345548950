import DataTable from "react-data-table-component";
import { useGetUsersQuery } from "../../../process/admin/users/usersApi";
import { useEffect } from "react";
// import Checkbox from "@material-ui/core/Checkbox";

// const sortIcon = <ArrowDownward />;

const columns = [
	{
		name: 'Firstname',
		selector: row => row.firstname,
	},
	{
		name: 'Lastname',
		selector: row => row.lastname,
	},
    {
        name: "Email",
        selector: row => row.email
    }
];

const Users = () => {
    const { data, isLoading, isError, isFetching } = useGetUsersQuery();
    return <DataTable pagination columns={columns} data={data || []} />;
};

export default Users;

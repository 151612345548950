import * as React from 'react';
import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
import { TextField, List, ListItem, ListItemText, Card, Icon, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { languages } from '../../../data/Hotel';

const PropertyLanguages = ({addlanguage, setLanguage}) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filteredLanguages, setFilteredLanguages] = React.useState(languages);
    const [show, setShow] = React.useState(false);
    const handleSearch = (event) => {
        setShow(true);
        const value = event.target.value.toLowerCase();
        console.log("value", value);
        setSearchTerm(value);
        setFilteredLanguages(
          languages.filter((language) =>
            language.language.toLowerCase().includes(value)
          )
        );
      };
   const addlanguageFun = (language) => {
    setShow(false);
     if(!addlanguage.includes(language)) {
        setLanguage([...addlanguage, language]);
     }
   }   

   const handleDelete = (language) => {
    setShow(false);
    if(addlanguage.includes(language)) {
        setLanguage(addlanguage.filter((_) => _ != language));
    }
}

  return(<>
    <Card sx={{ ml: '5px', padding: '16px' }}>
     <React.Fragment>
        <CardContent>
           {addlanguage.length > 0 && addlanguage.map((language) => {
            return <Chip
            key={language}
            label={language}
            onDelete={() => handleDelete(language)}
            deleteIcon={<p>X</p>}
            style={{ margin: '5px' }}
            />
           })  
            }
            <Typography variant="h5" component="div">
             {" Which languages are spoken at your property?"}
            </Typography>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
               {"Add a language"}
            </Typography>
            <TextField id="outlined-basic" label="Search" variant="outlined" onChange={handleSearch} />
            {show && <><List style={{ maxHeight: 400, overflow: 'auto' }}>
                {filteredLanguages.map((language, index) => (
                <ListItem key={index}>
                    <ListItemText primary={language.language} onClick={() => addlanguageFun(language.language)} />
                </ListItem>
                ))}
           </List></>}
            </CardContent>
    </React.Fragment>
  </Card>  
  </>)
}

export default PropertyLanguages;
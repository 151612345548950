import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import PropertyLanguage from './propertyLanguage';
import PaymentMethodAccept from './paymentMethodAccept';
import RequireAnyDeposit from './requireAnyDeposit';
import { MenuItem, Select } from '@mui/material';
import { acceptedPaymentForms } from '../../../data/Hotel';
import CleaningFeeComponent from './cleaningFee';
import RegulatoryInformation from './regulatoryInformation';
import DefaultCancellationPolicy from './defaultCancellationPolicy';
import TaxAndFees from './taxAndFees';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
const PolicyAndSetting = () => {
    const [addLanguage, setAddLanguage] = React.useState([]);

    const [acceptPayment, setAcceptPayment] = React.useState({
        credit_card: false,
        types_of_card: [],
        cash: false,
        only_cash_accepted: false
    });
    return (<>
         <div class="nk-content ">
                  <div class="container-fluid">
                     <div class="nk-content-inner">
                        <div class="nk-content-body">
                                <PropertyLanguage addlanguage={addLanguage} setLanguage={setAddLanguage} /><br></br>
                                <PaymentMethodAccept acceptPayment={acceptPayment} setAcceptPayment={setAcceptPayment} /><br></br>
                                <RequireAnyDeposit/><br></br>
                                <CleaningFeeComponent /><br></br>
                                <RegulatoryInformation inlineStyle={{marginLeft: "8px"}} /><br></br>
                                <DefaultCancellationPolicy /><br></br>
                                <TaxAndFees />
                        </div>
                     </div>
                  </div>
               </div>
    </>)
}
export default PolicyAndSetting;
import { Grid } from "@mui/material";
import React from "react";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';

const InfoBox = ({ heading, description, onEdit, onDelete, editClick, deleteClick }) => {
  return (
    <div className="max-w-sm mx-auto bg-white border border-gray-300 rounded-lg shadow-md p-6 relative">
      <div className="absolute top-3 right-3 flex space-x-2">
        {/* Edit Icon */}
        <Grid  item xs={8} onClick={editClick}>
        <EditIcon style={{ color: "blue" }} />
      </Grid>

        {/* Delete Icon */}
        <Grid  item xs={8} onClick={deleteClick}>
        <DeleteRoundedIcon style={{ color: "red" }} />
      </Grid>
      </div>

      <h2 className="text-lg font-semibold text-gray-800 mb-2">{heading}</h2>
      <p className="text-sm text-gray-600">{description && description[0]}</p>
      <p className="text-sm text-gray-600">{description && description[1]}</p>
    </div>
  );
};

export default InfoBox;

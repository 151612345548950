import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { acceptPaymentData } from "../../../data/Hotel";

const PaymentMethodAccept = ({ acceptPayment, setAcceptPayment }) => {
  const [showHandling, setShowHandling] = React.useState({
    cards: false,
    cash: false,
    only_cash_accepted: false,
  });
  const handleCard = (e) => {
    console.log("e.target.checked", e.target.checked);
    console.log("e.target.value", e.target.value);
    console.log("e.target.ma,e", e.target.name);
    // Credit and debit card

    if (e.target.name == "cards" && e.target.checked) {
      setAcceptPayment({
        credit_card: true,
        types_of_card: [],
        cash: acceptPayment.cash,
        only_cash_accepted: false,
      });
      setShowHandling({
        cards: true,
        cash: showHandling.cash,
        only_cash_accepted: false,
      });
    }

    if (e.target.name == "cards" && !e.target.checked) {
      setAcceptPayment({
        credit_card: false,
        types_of_card: [],
        cash: acceptPayment.cash,
        only_cash_accepted: acceptPayment.only_cash_accepted,
      });
      setShowHandling({
        cards: false,
        cash: showHandling.cash,
        only_cash_accepted: showHandling.only_cash_accepted,
      });
    }

    if (e.target.name == "card" && e.target.checked) {
      console.log("e.target.value", e.target.value);
      setAcceptPayment({
        credit_card: true,
        types_of_card: !acceptPayment.types_of_card?.includes(e.target.value)
          ? [...acceptPayment.types_of_card, e.target.value]
          : acceptPayment.types_of_card,
        cash: acceptPayment.cash,
        only_cash_accepted: false,
      });
      setShowHandling({
        cards: true,
        cash: true,
        only_cash_accepted: false,
      });
    }

    if (e.target.name == "card" && !e.target.checked) {
      setAcceptPayment({
        credit_card: true,
        types_of_card: acceptPayment.types_of_card.includes(e.target.value)
          ? acceptPayment.types_of_card.filter((_) => _ != e.target.value)
          : acceptPayment.types_of_card,
        cash: acceptPayment.cash,
        only_cash_accepted: false,
      });
      setShowHandling({
        cards: true,
        cash: showHandling.cash,
        only_cash_accepted: false,
      });
    }

    // Cash Checkbox
    if (e.target.name == "cash" && e.target.checked) {
      setShowHandling({
        cards: showHandling.cards,
        cash: true,
        only_cash_accepted: false,
      });
      setAcceptPayment({
        credit_card: true,
        types_of_card: acceptPayment.types_of_card.includes(e.target.value)
          ? acceptPayment.types_of_card.push(e.target.value)
          : acceptPayment.types_of_card,
        cash: acceptPayment.cash,
        only_cash_accepted: false,
      });
    }

    // Only Cash Accepted
    if (e.target.name == "only_cash_accepted" && e.target.checked) {
      setShowHandling({
        cards: false,
        cash: true,
        only_cash_accepted: true,
      });
      setAcceptPayment({
        credit_card: false,
        types_of_card: [],
        cash: acceptPayment.cash,
        only_cash_accepted: true,
      });
    }

    if (e.target.name == "only_cash_accepted" && !e.target.checked) {
      setShowHandling({
        cards: showHandling.cards,
        cash: showHandling.cash,
        only_cash_accepted: false,
      });
      setAcceptPayment({
        credit_card: acceptPayment.credit_card,
        types_of_card: acceptPayment.types_of_card,
        cash: acceptPayment.cash,
        only_cash_accepted: false,
      });
    }
  };

  useEffect(() => {
    console.log("setAcceptPayment", acceptPayment);
  }, [acceptPayment]);
  return (
    <>
      <Card sx={{ ml: "5px", padding: "16px" }}>
        <React.Fragment>
          <CardContent>
            <Typography variant="h5" component="div">
              {"Which payment methods do you accept at your property?"}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={showHandling.only_cash_accepted}
                  name="cards"
                  onClick={handleCard}
                />
              }
              label="Credit / debit cards"
            />
            {showHandling.cards && (
              <div style={{ marginLeft: "26px" }}>
                <Typography variant="h6" component="div">
                  {"Types of card you accept"}
                </Typography>
                {acceptPaymentData.length > 0 &&
                  acceptPaymentData.map((item) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="card"
                            value={item}
                            onClick={handleCard}
                          />
                        }
                        label={item}
                      />
                    );
                  })}
              </div>
            )}
            <FormControlLabel
              control={<Checkbox name="cash" onClick={handleCard} />}
              label="Cash"
            />
            {!showHandling.cards && showHandling.cash && (
              <FormControlLabel
                control={
                  <Checkbox name="only_cash_accepted" onClick={handleCard} />
                }
                label="Only Cash Accepted"
              />
            )}
          </CardContent>
        </React.Fragment>
      </Card>
    </>
  );
};

export default PaymentMethodAccept;

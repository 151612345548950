import React from "react";

import { Navigate } from "react-router-dom";
import Header from "../Layout/Main/Header";
import Footer from "../Layout/Main/Footer";
import PartnerHeader from "../Layout/Partner/header";
import BodyContainer from "../Tools/BodyContainer";
import PartnerSidebar from "../Layout/Partner/sidebar";
// import Header from "../components/layouts/header";
// import Footer from "../components/Layouts/Footer";

const withAuthorization = (Component, requiredRole, authRequire,location, label) => {
  return (props) => {
    const { isAuthenticated, role } = checkAuth();
    
    if (!isAuthenticated && authRequire==true) {
      return <Navigate to="/partner/login" />;
    }

    if (requiredRole && role !== requiredRole && authRequire==true) {
      return <Navigate to="/not-authorized" />;
    }
    switch(requiredRole) {
      case 'admin':
        return <BodyContainer 
        header={<PartnerHeader label={label} />}
        sidebar={<PartnerSidebar />}
        body={<Component {...props}  />}
        />;
      default:
        return <><Header location={location}/><Component {...props} /><Footer/></>;
    }
  };
};



const checkAuth = (selector) => {
  const lt = localStorage.getItem("token");
  if (lt && lt !== "") {
    return { isAuthenticated: true, role: "admin" };
  } else {
    return { isAuthenticated: false, role: "user" };
  }
};

export default withAuthorization;

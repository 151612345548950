import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";

const DateRangePickerFix = ({ val, onClose }) => {
  const [value, setValue] = useState([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        open={val} // Controlled by parent
        onClose={onClose} // Notify parent when to close
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
      <h6 className="padding-left">
        {value[0] && `${dayjs(value[0]).format("DD-MM")} - `}
        {value[1] && `${dayjs(value[1]).format("DD-MM")}`}
      </h6>
    </LocalizationProvider>
  );
};

export default DateRangePickerFix;

import React from "react";
const SuggestionsDiscovery = () => {
  return (
    <section class="suggestions-discovery">
    <div class="container">
      <div class="header-content">
        <h3 class="common-heading">Suggestions for <span class="additional-color-blue"> discovery</span></h3>
        <p class="common-paragraph">Popular places to recommends for you</p>
      </div>
      <div class="body-carousel">
        <div class="col-md-12">
          <div id="discoverycarousel" class="owl-carousel">
            <div class="post-slide">
              <img src="./assets/images/Houseboat-1.png"/>
              <div class="slider-text-app">
                <span>Houseboat </span>
              </div>

            </div>

            <div class="post-slide">
              <img src="./assets/images/Villa-2.png"/>
              <div class="slider-text-app">
                <span> Villa </span>
              </div>

            </div>

            <div class="post-slide">
              <img src="./assets/images/Appartment-3.png"/>
              <div class="slider-text-app">
                <span>Appartment </span>
              </div>

            </div>

            <div class="post-slide">
              <img src="./assets/images/Cottage-4.png"/>
              <div class="slider-text-app">
                <span> Cottage </span>
              </div>

            </div>

            <div class="post-slide">
              <img src="./assets/images/Appartment-3.png"/>
              <div class="slider-text-app">
                <span>Appartment </span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>);
};

export default SuggestionsDiscovery;

const BodyContainer = ({ sidebar, header, footer, body }) => {
  return (
    <>
      <div className="wrapper">
        {sidebar}
        <div id="content">
         {header}
         {body}
        </div>
      </div>
    </>
  );
};

export default BodyContainer;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../network';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    getTopListProduct: builder.query({
      query: ({state}) => ({
          url: `/api/v1/products/toplist?state=${state}`
      }),
      transformResponse: (response) => response.data
    }),
    getProduct: builder.query({
      query: ({id}) => ({
          url: `/api/v1/products/${id}`
      }),
      transformResponse: (response) => response.data
    }),
  }),
});

export const { useGetTopListProductQuery, useGetProductQuery } = productsApi;

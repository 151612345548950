import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

const CheckBoxContainer = ({ title, options, handleChange, defaultValue, name, errors, labelId, optionValue, optionLabel }) => {
//   const [selectedValues, setSelectedValues] = useState([]);

//   const handleCheckboxChange = (event) => {
//     const { name, checked } = event.target;

//     setSelectedValues((prev) =>
//       checked ? [...prev, name] : prev.filter((value) => value !== name)
//     );

//     if (handleChange) {
//       handleChange(name, checked); // Pass the selected option to the parent if needed
//     }
//   };

  return (
    <FormGroup>
      <Typography>{title}</Typography>
      <RadioGroup
                aria-labelledby={labelId}
                defaultValue={defaultValue}
                name={name}
                onChange={handleChange}
            >
                {options && options.length > 0 && options.map((item) => {
                    return (<><FormControlLabel value={item[optionValue]} control={<Radio />} label={item[optionLabel]} />
                    <Typography sx={{ fontSize: "10px" }}>
                    {item.description}
                  </Typography></>)
                    })}
               
      </RadioGroup>
   
      {errors && errors[name] && (
        <Typography sx={{ color: "red" }}>{errors[name]}</Typography>
      )}
    </FormGroup>
  );
};

export default CheckBoxContainer;

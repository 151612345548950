import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const SelectOptionComponent = ({ label, option, valueName, valueLabel, handleChange, labelId, id, name, style, errors, defaultValue, hideDefaultOption }) => {
    return (<FormControl style={style}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            labelId={labelId}
            id={id}
            // value={age}
            label={label}
            name={name}
            defaultValue={defaultValue}
            onChange={handleChange}
        >
            {!hideDefaultOption && <MenuItem value={""}>{"--Select--"}</MenuItem>}
            {option && option.length > 0 && option.map((item) => {
                return <MenuItem value={item[valueName]}>{item[valueLabel]}</MenuItem>
            })}
        </Select>
        {(errors && errors[name]) && <Typography sx={{color: "red"}}>{errors[name]}</Typography>}
    </FormControl>)
}

export default SelectOptionComponent;
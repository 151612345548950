import React, { useEffect } from "react";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";
import { useFormik } from "formik";
import { loginSchema, registrationSchema } from "../../../Schema/propertyRegistationSchema";
import { useLoginInMutation, useLoginMutation, useSignUpMutation } from "../../../Api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setAuth, setToken } from "../../../Slice/authSlice";
import { useDispatch } from "react-redux";



const LoginPage = () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const [
    loginIn,
    {
      data: loginData,
      isSuccess,
      error: loginErrors,
      isError: loginError,
      isLoading: loginLoading,
    },
  ] = useLoginInMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("loginError--->", loginErrors );
    console.log("isSucesss", isSuccess);

    if (isSuccess && !loginError) {
      // toast.success(registerData.data.message);
      localStorage.setItem('user', JSON.stringify(loginData.data.user));
      localStorage.setItem('token', loginData.data.token);
      dispatch(setAuth(loginData.data.user));
      dispatch(setToken(loginData.data.token));
      // alert(loginData.data.message)
      if(loginData.data.user?.role_id===1) {
        navigate('/admin/dashboard');
        window.location.href = "/admin/dashboard";
      } else {
        navigate('/partner/rates');
        window.location.href = "/partner/rates";
      }
      toast.success(loginData.data.message);
    }
    else if (loginErrors && loginErrors.data) {
      alert(loginErrors?.data?.message);
      toast.error(loginErrors?.data?.message);
    }
    else if (loginErrors && loginErrors.error) {
      alert(loginErrors?.error);
      toast.error(loginErrors?.error);
    }
  }, [isSuccess, loginError]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { resetForm }) => {
      console.debug("Form Data: ", values);
      loginIn(values);
      resetForm();
    },
  });

  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* Left Section with Image */}
   

      {/* Right Section with Form */}
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          bgcolor: "#f7f8fc",
        }}
      >
        {/* Logo */}
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333" }}>
          <img src="/getMyHotelLogo.png" />
        </Typography>

        {/* Form Container */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            mt: 4,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            padding: 3,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                mb: 1,
                textAlign: "center",
                color: "#333",
              }}
            >
              {"Login"}
            </Typography>
            {/* <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "#777", mb: 3 }}
            >
              {"Please fill in your details to create an account"}
            </Typography> */}

            {/* Input Fields */}
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              size="small"
              type="email"
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              sx={{ mb: 2 }}
            />
            
            <TextField
              label="Set Password"
              fullWidth
              variant="outlined"
              size="small"
              type="password"
              name="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              sx={{ mb: 2 }}
            />

            {/* Register Button */}
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#7b2ff7",
                color: "white",
                fontWeight: "bold",
                ":hover": { backgroundColor: "#631cd3" },
              }}
            >
              Login
            </Button>
          </form>
        </Box>
        {/* Footer */}
        <Typography
          variant="caption"
          sx={{ mt: 4, color: "#aaa", textAlign: "center" }}
        >
          Do not have account ? <Typography variant="p" sx={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => navigate('/sign-up')}>{"Sign up"}</Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginPage;


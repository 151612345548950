import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const BestFacalities = ({state}) => {
  return (
    <section class="best-facalities book-desire-hotel">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 ">
          <div class="content book-px">
            <h3> Our
              <span class="additional-color-blue"> Best Facilities</span>
              Provide You
            </h3>
            <p>Accommodations are designed to provide most
              comfort and relaxation. we ensure a memorable
              stay for our guests.</p>
            <button>Contact us Now</button>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 ">
          <div class="row mb-3 book-px">
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="./assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6 ">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-6">
              <div class="best-facalities-card">
                <img src="assets/images/facalities-one.svg"/>
                <p>Private workplace</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  </section>
  );
};

export default BestFacalities;

const RatesAndAvailablity = () => {
  return (
    <div class="dashboard-container">
      {/* <!-- Stats Section --> */}
      <div class="stats-section">
        <div class="row">
          <div class="col-12">
            <div class="calendar-header">
              <div class="calendar-header-detail">
                <div>
                  <h2 id="month-year">October, 2024</h2>
                </div>
                <div>
                  <button
                    class="btn btn-light prevMonth-btn"
                    onclick="prevMonth()"
                  >
                    &lt;
                  </button>
                  <button
                    class="btn btn-light prevMonth-btn"
                    onclick="nextMonth()"
                  >
                    &gt;
                  </button>
                </div>
              </div>

              <div class="modal-flex">
                <div>
                  {/* <!-- Button trigger modal --> */}
                  <button
                    type="button"
                    class="btn btn-outline-primary slect-room-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Select Room{" "}
                    <i class="bi bi-5-circle-fill bi-5-circle-fill-icon"></i>
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <div>
                            <h5 class="modal-title" id="exampleModalLabel">
                              Choose your room types
                            </h5>
                            <p>
                              {" "}
                              No more than 200 rate plans can be associated with
                              the selected room type{" "}
                            </p>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div class="check-box-modal">
                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span> Select all </span>{" "}
                                </p>
                              </label>
                            </div>
                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span> Full Room </span> (ID: 316500864, Code:
                                  Full Room) <br />2 rate plans{" "}
                                </p>
                              </label>
                            </div>

                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span>Queen Room </span> (ID: 316500864, Code:
                                  Queen) <br /> 2 rate plans{" "}
                                </p>
                              </label>
                            </div>
                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span>Standard Room </span> (ID: 316500864,
                                  Code :Standard) <br />2 rate plans{" "}
                                </p>
                              </label>
                            </div>
                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span> Standard Queen Room </span> (ID:
                                  316500864, Code :Queen Room) <br />2 rate
                                  plans{" "}
                                </p>
                              </label>
                            </div>
                            <div class="form-check mt-2 mb-2">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <p>
                                  {" "}
                                  <span> Twin Bed </span> (ID: 316500864, Code
                                  :TWIN BED) <br />2 rate plans{" "}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div class="selected-modal-text">
                            <p>
                              {" "}
                              <span>
                                Selected: 5 room types 14 rate plans
                              </span>{" "}
                              <br />
                              186 more rate plans are allowed{" "}
                            </p>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary cancel-btn"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {/* <!-- Button trigger modal --> */}
                  <button
                    type="button"
                    class="btn btn-outline-primary slect-room-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Customize view{" "}
                    <i class="bi bi-5-circle-fill bi-5-circle-fill-icon"></i>
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <div class="modal-title-static mt-3 mb-2">
                            <h6 class="modal-title" id="staticBackdropLabel">
                              Select what you’d like to see regarding your room
                              inventory and rates.
                            </h6>
                            <span>Select all </span>
                          </div>

                          {/* <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button> --> */}
                        </div>
                        <div class="modal-body">
                          <div class="room-type-show mt-4 mb-2">
                            <h5> For each room type, show: </h5>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span> Inventory </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span> Number of rooms booked </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span> Booking cutoff </span>
                              </label>
                            </div>
                          </div>
                          <div class="room-type-show mt-4 mb-2">
                            <h5>For each rate plan, show: </h5>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span> Rate </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span>
                                  {" "}
                                  Minimum and maximum length of stay{" "}
                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input form-check-box"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckChecked"
                              >
                                <span> Advance purchase window </span>
                              </label>
                            </div>
                            <div class="mt-3">
                              <p>
                                {" "}
                                Minimum and maximum days will be displayed.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary cancel-btn"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary submit-btn"
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="calendar-dates">
              <div class="fw-bold">
                <p>
                  {" "}
                  Mon
                  <br /> <span>02 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Tue
                  <br /> <span>03 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Wed
                  <br /> <span>04 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Thu
                  <br /> <span>05 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Fri
                  <br />
                  <span> 06 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Sat
                  <br /> <span>07 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Sun
                  <br />
                  <span> 08 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Mon
                  <br /> <span>09 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Tue
                  <br /> <span>10 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Wed
                  <br /> <span>11 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Sat
                  <br /> <span>12 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Sun
                  <br /> <span>18 </span> <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Mon
                  <br />
                  <span> 14 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Tue
                  <br /> <span>15 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  {" "}
                  Wed
                  <br />
                  <span> 16 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Tue
                  <br /> <span>17 </span>
                  <br /> Oct{" "}
                </p>
              </div>
              <div class="fw-bold">
                <p>
                  Wed
                  <br /> <span>18 </span>
                  <br /> Oct{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fullroom-table">
        <div class="room-row">
          <div class="room-type">
            <i class="bi bi-chevron-down"></i>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle fill-room-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Full Room
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span>(ID : 316500864 Code Full Room) </span>
        </div>
        <div class="availability-cell-border">
          <div class="availability-grid">
            <div class="availability-heading">
              <h6>Availability </h6>
            </div>

            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
          </div>
        </div>
      </div>
      <div class="fullroom-table">
        <div class="room-row">
          <div class="room-type">
            <i class="bi bi-chevron-down"></i>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle fill-room-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Queen Room
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span>(ID : 316500864 Code QUEEN)</span>
        </div>
        <div class="availability-cell-border">
          <div class="availability-grid">
            <div class="availability-heading">
              <h6>Availability </h6>
            </div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
          </div>
        </div>
      </div>
      <div class="fullroom-table">
        <div class="room-row">
          <div class="room-type">
            <i class="bi bi-chevron-down"></i>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle fill-room-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Standard Room
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span>(ID : 316500864 Code Standard)</span>
        </div>
        <div class="availability-cell-border">
          <div class="availability-grid">
            <div class="availability-heading">
              <h6>Availability </h6>
            </div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
          </div>
        </div>
      </div>
      <div class="fullroom-table">
        <div class="room-row">
          <div class="room-type">
            <i class="bi bi-chevron-down"></i>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle fill-room-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Standard Queen Room
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span>(ID : 316500864 Code Queen Room) </span>
        </div>
        <div class="availability-cell-border">
          <div class="availability-grid">
            <div class="availability-heading">
              <h6>Availability </h6>
            </div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
          </div>
        </div>
      </div>
      <div class="fullroom-table">
        <div class="room-row">
          <div class="room-type">
            <i class="bi bi-chevron-down"></i>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle fill-room-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Twin Bed
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <span>(ID : 316500864 Code Full Room) </span>
        </div>
        <div class="availability-cell-border">
          <div class="availability-grid">
            <div class="availability-heading">
              <h6>Availability </h6>
            </div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell unavailable">✘</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
            <div class="availability-cell available">✔</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatesAndAvailablity;

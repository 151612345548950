import { Box, Typography } from "@mui/material";

const CommonTextArea = ({
  id,
  style,
  className,
  name,
  onChange,
  value,
  boxWidth,
  mainHeading,
  childHeading,
  error
}) => {
  return (
    <Box
      component="section"
      sx={{
        p: 2,
        border: "1px solid white",
        borderRadius: "10px",
        background: "#f3f3f5",
        width: boxWidth,
      }}
    >
      <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
        {mainHeading}
      </Typography>
      <Typography>
        {childHeading}
      </Typography>
      <textarea
        style={style}
        id={id}
        className={className}
        value={value}
        onChange={onChange}
        name={name}
        
      >
        {value}
      </textarea>
      {error && error[name] && <p style={{color: "red"}}>{error[name]}</p>}
    </Box>
  );
};

export default CommonTextArea;

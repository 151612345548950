import { selectClasses } from "@mui/material";

export const registrationHotelSidebarItem = [
  {
    title: "Basic property information",
    linking: "/basic",
    name: "basic",
    titletStyle: "nk-menu-text",
    liStyle: "nk-menu-item",
    linkStyle: "sidebar-active",
  },
  // {
  //   title: "Property contract",
  //   linking: "/property_contract",
  //   titletStyle: "nk-menu-text",
  //   liStyle: "nk-menu-item",
  //   linkStyle: "",
  // },
  // {
  //   title: "Policies and setting",
  //   linking: "/policy_and_setting",
  //   titletStyle: "nk-menu-text",
  //   liStyle: "nk-menu-item",
  //   linkStyle: "",
  // },
  {
    title: "Property amenities",
    linking: "/property_amenties",
    titletStyle: "nk-menu-text",
    name: "property_amenties",
    liStyle: "nk-menu-item",
    linkStyle: "",
  },
  {
    title: "Room and rates",
    linking: "/room_and_rates",
    titletStyle: "nk-menu-text",
    name: "room_and_rates",
    liStyle: "nk-menu-item",
    linkStyle: "",
  },
  // {
  //   title: "Confirm rates and availability",
  //   linking: "/demo1/hotel/index.html",
  //   titletStyle: "nk-menu-text",
  //   liStyle: "nk-menu-item",
  //   linkStyle: "",
  // },
  {
    title: "photos",
    linking: "/room_upload_photos",
    name: "room_upload_photos",
    titletStyle: "nk-menu-text",
    liStyle: "nk-menu-item",
    linkStyle: "",
  },
  {
    title: "Taxes",
    linking: "/property_taxes",
    name: "property_taxes",
    titletStyle: "nk-menu-text",
    liStyle: "nk-menu-item",
    linkStyle: "",
  },
  {
    title: "Point of interest",
    linking: "/point_of_interest",
    name: "point_of_interest",
    titletStyle: "nk-menu-text",
    liStyle: "nk-menu-item",
    linkStyle: "",
  },
  // {
  //   title: "Review",
  //   linking: "/demo1/hotel/index.html",
  //   titletStyle: "nk-menu-text",
  //   liStyle: "nk-menu-item",
  //   linkStyle: "",
  // },
];

export const languages = [
  { language: "Afrikaans" },
  { language: "Arab (Sign language)" },
  { language: "Azerbaijani" },
  { language: "Belarusian" },
  { language: "British-BANZSL (Sign language)" },
  { language: "Bulgarian" },
  { language: "Catalan" },
  { language: "Chinese (Cantonese)" },
  { language: "Chinese (Mandarin)" },
  { language: "Chinese (Sign language)" },
  { language: "Croatian" },
  { language: "Czech" },
  { language: "Czech (Sign language)" },
  { language: "Danish" },
  { language: "Danish (Sign language)" },
  { language: "Dutch" },
  { language: "English" },
  { language: "Estonian" },
  { language: "Farsi" },
  { language: "Filipino" },
  { language: "Finnish" },
  { language: "French" },
  { language: "French (Sign language)" },
  { language: "Georgian" },
  { language: "German" },
  { language: "German (Sign language)" },
  { language: "Greek" },
  { language: "Hausa" },
  { language: "Hebrew" },
  { language: "Hindi" },
  { language: "Hungarian" },
  { language: "Icelandic" },
  { language: "Indo-Pakistani (Sign language)" },
  { language: "Indonesian" },
  { language: "Irish" },
  { language: "Italian" },
  { language: "Japanese" },
  { language: "Japanese (Sign language)" },
  { language: "Khmer" },
  { language: "Korean" },
  { language: "Lao" },
  { language: "Latvian" },
  { language: "Lithuanian" },
  { language: "Local (Sign language)" },
  { language: "Macedonian" },
  { language: "Malay" },
  { language: "Maltese" },
  { language: "Moldovan" },
  { language: "Mongolian" },
  { language: "Norwegian" },
  { language: "Polish" },
  { language: "Portuguese" },
  { language: "Romanian" },
  { language: "Russian" },
  { language: "Russian (Sign language)" },
  { language: "Serbian" },
  { language: "Slovak" },
  { language: "Slovenian" },
  { language: "South African (Sign language)" },
  { language: "Spanish" },
  { language: "Swahili" },
  { language: "Swedish" },
  { language: "Swedish (Sign language)" },
  { language: "Thai" },
  { language: "Turkish" },
  { language: "Ukrainian" },
  { language: "Urdu" },
  { language: "Vietnamese" },
  { language: "Vietnamese (Sign language)" },
  { language: "Welsh" },
  { language: "Xhosa" },
  { language: "Yoruba" },
  { language: "Zulu" },
];

export const acceptPaymentData = [
  "Debit cards",
  "JCB International",
  "Visa",
  "Discover",
  "Mastercard",
  "Carte Blanche",
  "American Express",
  "UnionPay",
  "Diners Club",
];

export const requireAnyDeposits = [
  {
    label: "General Deposit",
    type: "checkbox",
    ischecked: false,
    name: "general_deposits",
    data: [
      {
        label: "",
        key: 1,
        data: [
          {
            label: "Year Round",
            name: "round",
            type: "radio",
            mainIndex: 1,
            ischecked: false,
          },
          {
            label: "Date Range",
            name: "round",
            key: 1,
            type: "radio",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "From",
                type: "date",
                name: "general_date_range_from",
                placeholder: "d/m",
              },
              {
                label: "To",
                type: "date",
                placeholder: "d/m",
                name: "general_date_range_to",
              },
            ],
          },
        ],
      },
      {
        label: "Deposit applies to guests",
        key: 2,
        data: [
          {
            label: "of all ages",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
          },
          {
            label: "under a specific age",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
            data: [
              {
                label: "Only for guests under this age",
                name: "guest_age",
                type: "select",
                data: [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Amount in INR (including taxes)",
        name: "amount",
        type: "number",
        key: 3,
      },
      {
        label: "Scope",
        type: "select",
        name: "scope",
        default: "Per Person",
        key: 3,
        data: [{ label: "Per Person" }, { label: "Per Accomdation" }],
      },
      {
        label: "Duration",
        type: "select",
        name: "duration",
        default: "Per day",
        key: 3,
        data: [
          { label: "Per day" },
          { label: "Per night" },
          { label: "Per stay" },
          { label: "Per week" },
        ],
      },
      {
        label: "Cash Only",
        name: "cash_only",
        key: 3,
        type: "checkbox",
      },
    ],
  },
  {
    label: "Breakage/cleaning deposit",
    type: "checkbox",
    // ischecked: false,
    name: "Breakage_cleaning_deposit",
    data: [
      {
        label: "",
        key: 1,
        data: [
          {
            label: "Breakage deposit",
            name: "deposit",
            type: "radio",
            mainIndex: 1,
            ischecked: false,
          },
          {
            label: "Cleaning deposit",
            name: "deposit",
            type: "radio",
            mainIndex: 1,
            ischecked: false,
          },
        ],
      },

      {
        label: "",
        key: 2,
        data: [
          {
            label: "Year Round",
            name: "round",
            type: "radio",
            mainIndex: 1,
            ischecked: false,
          },
          {
            label: "Date Range",
            name: "round",
            key: 2,
            type: "radio",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "From",
                type: "date",
                name: "general_date_range_from",
                placeholder: "d/m",
              },
              {
                label: "To",
                type: "date",
                placeholder: "d/m",
                name: "general_date_range_to",
              },
            ],
          },
        ],
      },

      {
        label: "Deposit applies to guests",
        key: 2,
        data: [
          {
            label: "of all ages",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
          },
          {
            label: "under a specific age",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
            data: [
              {
                label: "Only for guests under this age",
                name: "guest_age",
                type: "select",
                data: [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Amount in INR (including taxes)",
        name: "amount",
        type: "number",
        key: 3,
      },
      {
        label: "Duration",
        type: "select",
        name: "duration",
        default: "Per day",
        key: 3,
        data: [
          { label: "Per day" },
          { label: "Per night" },
          { label: "Per stay" },
          { label: "Per week" },
        ],
      },
      {
        label: "Cash Only",
        name: "cash_only",
        key: 3,
        type: "checkbox",
      },
    ],
  },
  {
    label: "Spring break deposit",
    type: "checkbox",
    // ischecked: false,
    name: "spring_break_deposit",
    data: [
      {
        label: "",
        key: 1,
        data: [
          {
            label: "From",
            type: "date",
            name: "from_spring_break_deposit",
          },
          {
            label: "To",
            type: "date",
            name: "to_spring_break_deposit",
          },
        ],
      },
      {
        label: "Deposit applies to guests",
        key: 2,
        data: [
          {
            label: "of all ages",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
          },
          {
            label: "under a specific age",
            name: "deposit_applies_guest",
            mainIndex: 1,
            type: "radio",
            ischecked: false,
            data: [
              {
                label: "Only for guests under this age",
                name: "guest_age",
                type: "select",
                data: [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Damage deposit collected before arrival",
    type: "checkbox",
    // ischecked: false,
    name: "damage_deposit_collected",
    data: [
      {
        label: "",
        key: 1,
        data: [
          {
            label: "Amount in INR (including taxes)",
            name: "amount",
            type: "number",
            key: 1,
          },
        ],
      },
    ],
  },
  {
    label: "Alternative deposit payment",
    type: "checkbox",
    // ischecked: false,
    name: "alternative_deposit_payment",
    data: [
      {
        label: "Payment Type",
        type: "select",
        name: "alternative_payment_type",
        key: 1,
        default: "Per day",
        data: [
          { label: "Per day" },
          { label: "Per night" },
          { label: "Per stay" },
          { label: "Per week" },
        ],
      },
      {
        label: "Payment due after booking",
        type: "select",
        name: "alternative_payment_due_booking",
        key: 1,
        default: "24 hours",
        data: [
          { label: "24 hours" },
          { label: "48 hours" },
          { label: "72 hours" },
        ],
      },
    ],
  },
];

export const acceptedPaymentForms = [
  {
    label: "Cash only",
  },
  {
    label: "Credit card only",
  },
  {
    label: "Debit card only",
  },
  {
    label: "Cash and credit card only ",
  },
  {
    label: "Cash, credit cards, debit cards",
  },
];

export const taxAndFeesData = [
  {
    label: "City tax",
    name: "city_tax",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Scope",
                type: "select",
                name: "scope",
                default: "Per Person",
                key: 3,
                data: [{ label: "Per Person" }, { label: "Per Accomdation" }],
              },
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Scope",
                type: "select",
                name: "scope",
                default: "Per Person",
                key: 3,
                data: [{ label: "Per Person" }, { label: "Per Accomdation" }],
              },
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Federal tax",
    name: "federal_tax",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Occupancy tax",
    name: "occupancy_tax",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "District tax",
    name: "district_tax",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Hotel tax",
    name: "hotel_tax",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Goods and services tax (GST)",
    name: "gst",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Harmonised sales tax (HST)",
    name: "hst",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Value added tax (VAT)",
    name: "vat",
    ischecked: false,
    data: [
      {
        label: "Type",
        name: "type",
        key: 1,
        class: "",
        type: "select",
        default: "percent_per_stay",
        ischecked: false,
        mainIndex: 1,
        typeOption: "option_inside_input",
        data: [
          {
            label: "Percent per stay",
            class: "",
            name: "percent_per_stay",
            ischecked: true,
            mainIndex: 1,
            data: [
              {
                label: "Percent (%)",
                type: "number",
                name: "percent",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per stay",
            type: "number",
            class: "",
            name: "amount_per_stay",
            ischecked: false,
            mainIndex: 1,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
          {
            label: "Amount per night",
            type: "number",
            class: "",
            name: "amount_per_night",
            mainIndex: 1,
            ischecked: false,
            data: [
              {
                label: "Amount",
                type: "number",
                name: "amount",
                placeholder: "Amount",
                key: 3,
              },
            ],
          },
        ],
      },
    ],
  },
];

export let basicPropertyAmenties = {
  front_desk: [
    {
      label: "Every Day",
      name: "every_day",
      checked: false,
      data: [
        {
          label: "Desk opens",
          name: "desk_opens",
          type: "select",
          // value: "",
          id: "desk_open",
          data: [
            {
              label: "Select the option",
              value: "",
              selected: true,
            },
            {
              label: "6:00 AM",
              value: "6:00 AM",
            },
            {
              label: "6:30 AM",
              value: "6:30 AM",
            },
            {
              label: "7:00 AM",
              value: "7:00 AM",
            },
            {
              label: "7:30 AM",
              value: "7:30 AM",
            },
            {
              label: "8:00 AM",
              value: "8:00 AM",
            },
            {
              label: "8:30 AM",
              value: "8:30 AM",
            },
            {
              label: "9:00 AM",
              value: "9:00 AM",
            },
            {
              label: "9:30 AM",
              value: "9:30 AM",
            },
            {
              label: "10:00 AM",
              value: "10:00 AM",
            },
            {
              label: "10:30 AM",
              value: "10:30 AM",
            },
            {
              label: "11:00 AM",
              value: "11:00 AM",
            },
            {
              label: "11:30 AM",
              value: "11:30 AM",
            },
            {
              label: "noon",
              value: "noon",
            },
            {
              label: "12:30 PM",
              value: "12:30 PM",
            },
            {
              label: "1:00 PM",
              value: "1:00 PM",
            },
            {
              label: "1:30 PM",
              value: "1:30 PM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 PM",
              value: "2:30 PM",
            },
            {
              label: "3:00 PM",
              value: "3:00 PM",
            },
            {
              label: "3:30 PM",
              value: "3:30 PM",
            },
            {
              label: "4:00 PM",
              value: "4:00 PM",
            },
            {
              label: "4:30 PM",
              value: "4:30 PM",
            },
            {
              label: "5:00 PM",
              value: "5:00 PM",
            },
            {
              label: "5:30 PM",
              value: "5:30 PM",
            },
            {
              label: "6:00 PM",
              value: "6:00 PM",
            },
            {
              label: "6:30 PM",
              value: "6:30 PM",
            },
            {
              label: "7:00 PM",
              value: "7:00 PM",
            },
            {
              label: "7:30 PM",
              value: "7:30 PM",
            },
            {
              label: "8:00 PM",
              value: "8:00 PM",
            },
            {
              label: "8:30 PM",
              value: "8:30 PM",
            },
            {
              label: "9:00 PM",
              value: "9:00 PM",
            },
            {
              label: "9:30 PM",
              value: "9:30 PM",
            },
            {
              label: "10:00 PM",
              value: "10:00 PM",
            },
            {
              label: "10:30 PM",
              value: "10:30 PM",
            },
            {
              label: "11:00 PM",
              value: "11:00 PM",
            },
            {
              label: "11:30 PM",
              value: "11:30 PM",
            },
            {
              label: "Midnight",
              value: "Midnight",
            },
            {
              label: "12:30 AM",
              value: "12:30 AM",
            },
            {
              label: "1:00 AM",
              value: "1:00 AM",
            },
            {
              label: "1:30 AM",
              value: "1:30 AM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 AM",
              value: "2:30 AM",
            },

            {
              label: "3:00 AM",
              value: "3:00 AM",
            },
            {
              label: "3:30 AM",
              value: "3:30 AM",
            },
            {
              label: "4:00 AM",
              value: "4:00 AM",
            },
            {
              label: "4:30 AM",
              value: "4:30 AM",
            },
            {
              label: "5:00 AM",
              value: "5:00 AM",
            },
            {
              label: "5:30 AM",
              value: "5:30 AM",
            },
          ],
        },
        {
          label: "Desk closes",
          name: "desk_closes",
          type: "select",
          id: "desk_close",
          data: [
            {
              label: "Select the option",
              value: "",
              selected: true,
            },
            {
              label: "6:00 AM",
              value: "6:00 AM",
            },
            {
              label: "6:30 AM",
              value: "6:30 AM",
            },
            {
              label: "7:00 AM",
              value: "7:00 AM",
            },
            {
              label: "7:30 AM",
              value: "7:30 AM",
            },
            {
              label: "8:00 AM",
              value: "8:00 AM",
            },
            {
              label: "8:30 AM",
              value: "8:30 AM",
            },
            {
              label: "9:00 AM",
              value: "9:00 AM",
            },
            {
              label: "9:30 AM",
              value: "9:30 AM",
            },
            {
              label: "10:00 AM",
              value: "10:00 AM",
            },
            {
              label: "10:30 AM",
              value: "10:30 AM",
            },
            {
              label: "11:00 AM",
              value: "11:00 AM",
            },
            {
              label: "11:30 AM",
              value: "11:30 AM",
            },
            {
              label: "noon",
              value: "noon",
            },
            {
              label: "12:30 PM",
              value: "12:30 PM",
            },
            {
              label: "1:00 PM",
              value: "1:00 PM",
            },
            {
              label: "1:30 PM",
              value: "1:30 PM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 PM",
              value: "2:30 PM",
            },
            {
              label: "3:00 PM",
              value: "3:00 PM",
            },
            {
              label: "3:30 PM",
              value: "3:30 PM",
            },
            {
              label: "4:00 PM",
              value: "4:00 PM",
            },
            {
              label: "4:30 PM",
              value: "4:30 PM",
            },
            {
              label: "5:00 PM",
              value: "5:00 PM",
            },
            {
              label: "5:30 PM",
              value: "5:30 PM",
            },
            {
              label: "6:00 PM",
              value: "6:00 PM",
            },
            {
              label: "6:30 PM",
              value: "6:30 PM",
            },
            {
              label: "7:00 PM",
              value: "7:00 PM",
            },
            {
              label: "7:30 PM",
              value: "7:30 PM",
            },
            {
              label: "8:00 PM",
              value: "8:00 PM",
            },
            {
              label: "8:30 PM",
              value: "8:30 PM",
            },
            {
              label: "9:00 PM",
              value: "9:00 PM",
            },
            {
              label: "9:30 PM",
              value: "9:30 PM",
            },
            {
              label: "10:00 PM",
              value: "10:00 PM",
            },
            {
              label: "10:30 PM",
              value: "10:30 PM",
            },
            {
              label: "11:00 PM",
              value: "11:00 PM",
            },
            {
              label: "11:30 PM",
              value: "11:30 PM",
            },
            {
              label: "Midnight",
              value: "Midnight",
            },
            {
              label: "12:30 AM in a next day",
              value: "12:30 AM in a next day",
            },
            {
              label: "1:00 AM in a next day",
              value: "1:00 AM in a next day",
            },
            {
              label: "1:30 AM in a next day",
              value: "1:30 AM in a next day",
            },
            {
              label: "2:00 AM in a next day",
              value: "2:00 AM",
            },
            {
              label: "2:30 AM",
              value: "2:30 AM in a next day",
            },

            {
              label: "3:00 AM in a next day",
              value: "3:00 AM in a next day",
            },
            {
              label: "3:30 AM in a next day",
              value: "3:30 AM in a next day",
            },
            {
              label: "4:00 AM in a next day",
              value: "4:00 AM in a next day",
            },
            {
              label: "4:30 AM in a next day",
              value: "4:30 AM in a next day",
            },
            {
              label: "5:00 AM in a next day",
              value: "5:00 AM in a next day",
            },
            {
              label: "5:30 AM in a next day",
              value: "5:30 AM in a next day",
            },
          ],
        },
        {
          label: "Open 24 hours",
          name: "open_24_hours",
          targetIdDisabled: ["desk_open", "desk_close"],
          type: "checkbox",
        },
      ],  
    },
    {
      label: "Select Day",
      name: "select_day",
      checked: false,
      data: [
        {
          label: "Start Day",
          name: "start_day",
          type: "select",
          // value: "",
          id: "start_day",
          data: [
            {
              label: "--select--",
              value: "",
            },
            {
              label: "Monday",
              value: "Monday",
            },
            {
              label: "Tuesday",
              value: "Tuesday",
            },
            {
              label: "Wednesday",
              value: "Wednesday",
            },
            {
              label: "Thursday",
              value: "Thursday",
            },
            {
              label: "Friday",
              value: "Friday",
            },
            {
              label: "Saturday",
              value: "Saturday",
            },
            {
              label: "Sunday",
              value: "Sunday",
            },
          ],
        },
        {
          label: "End Day",
          name: "end_day",
          type: "select",
          // value: "",
          id: "end_day",
          data: [
            {
              label: "--select--",
              value: "",
            },
            {
              label: "Monday",
              value: "Monday",
            },
            {
              label: "Tuesday",
              value: "Tuesday",
            },
            {
              label: "Wednesday",
              value: "Wednesday",
            },
            {
              label: "Thursday",
              value: "Thursday",
            },
            {
              label: "Friday",
              value: "Friday",
            },
            {
              label: "Saturday",
              value: "Saturday",
            },
            {
              label: "Sunday",
              value: "Sunday",
            },
          ],
        },
        {
          label: "Desk opens",
          name: "desk_open",
          type: "select",
          // value: "",
          id: "desk_open",
          data: [
            {
              label: "Select the option",
              value: "",
              selected: true,
            },
            {
              label: "6:00 AM",
              value: "6:00 AM",
            },
            {
              label: "6:30 AM",
              value: "6:30 AM",
            },
            {
              label: "7:00 AM",
              value: "7:00 AM",
            },
            {
              label: "7:30 AM",
              value: "7:30 AM",
            },
            {
              label: "8:00 AM",
              value: "8:00 AM",
            },
            {
              label: "8:30 AM",
              value: "8:30 AM",
            },
            {
              label: "9:00 AM",
              value: "9:00 AM",
            },
            {
              label: "9:30 AM",
              value: "9:30 AM",
            },
            {
              label: "10:00 AM",
              value: "10:00 AM",
            },
            {
              label: "10:30 AM",
              value: "10:30 AM",
            },
            {
              label: "11:00 AM",
              value: "11:00 AM",
            },
            {
              label: "11:30 AM",
              value: "11:30 AM",
            },
            {
              label: "noon",
              value: "noon",
            },
            {
              label: "12:30 PM",
              value: "12:30 PM",
            },
            {
              label: "1:00 PM",
              value: "1:00 PM",
            },
            {
              label: "1:30 PM",
              value: "1:30 PM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 PM",
              value: "2:30 PM",
            },
            {
              label: "3:00 PM",
              value: "3:00 PM",
            },
            {
              label: "3:30 PM",
              value: "3:30 PM",
            },
            {
              label: "4:00 PM",
              value: "4:00 PM",
            },
            {
              label: "4:30 PM",
              value: "4:30 PM",
            },
            {
              label: "5:00 PM",
              value: "5:00 PM",
            },
            {
              label: "5:30 PM",
              value: "5:30 PM",
            },
            {
              label: "6:00 PM",
              value: "6:00 PM",
            },
            {
              label: "6:30 PM",
              value: "6:30 PM",
            },
            {
              label: "7:00 PM",
              value: "7:00 PM",
            },
            {
              label: "7:30 PM",
              value: "7:30 PM",
            },
            {
              label: "8:00 PM",
              value: "8:00 PM",
            },
            {
              label: "8:30 PM",
              value: "8:30 PM",
            },
            {
              label: "9:00 PM",
              value: "9:00 PM",
            },
            {
              label: "9:30 PM",
              value: "9:30 PM",
            },
            {
              label: "10:00 PM",
              value: "10:00 PM",
            },
            {
              label: "10:30 PM",
              value: "10:30 PM",
            },
            {
              label: "11:00 PM",
              value: "11:00 PM",
            },
            {
              label: "11:30 PM",
              value: "11:30 PM",
            },
            {
              label: "Midnight",
              value: "Midnight",
            },
            {
              label: "12:30 AM",
              value: "12:30 AM",
            },
            {
              label: "1:00 AM",
              value: "1:00 AM",
            },
            {
              label: "1:30 AM",
              value: "1:30 AM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 AM",
              value: "2:30 AM",
            },

            {
              label: "3:00 AM",
              value: "3:00 AM",
            },
            {
              label: "3:30 AM",
              value: "3:30 AM",
            },
            {
              label: "4:00 AM",
              value: "4:00 AM",
            },
            {
              label: "4:30 AM",
              value: "4:30 AM",
            },
            {
              label: "5:00 AM",
              value: "5:00 AM",
            },
            {
              label: "5:30 AM",
              value: "5:30 AM",
            },
          ],
        },
        {
          label: "Desk closes",
          name: "desk_closes",
          id: "desk_closes",
          data: [
            {
              label: "Select the option",
              value: "",
              selected: true,
            },
            {
              label: "6:00 AM",
              value: "6:00 AM",
            },
            {
              label: "6:30 AM",
              value: "6:30 AM",
            },
            {
              label: "7:00 AM",
              value: "7:00 AM",
            },
            {
              label: "7:30 AM",
              value: "7:30 AM",
            },
            {
              label: "8:00 AM",
              value: "8:00 AM",
            },
            {
              label: "8:30 AM",
              value: "8:30 AM",
            },
            {
              label: "9:00 AM",
              value: "9:00 AM",
            },
            {
              label: "9:30 AM",
              value: "9:30 AM",
            },
            {
              label: "10:00 AM",
              value: "10:00 AM",
            },
            {
              label: "10:30 AM",
              value: "10:30 AM",
            },
            {
              label: "11:00 AM",
              value: "11:00 AM",
            },
            {
              label: "11:30 AM",
              value: "11:30 AM",
            },
            {
              label: "noon",
              value: "noon",
            },
            {
              label: "12:30 PM",
              value: "12:30 PM",
            },
            {
              label: "1:00 PM",
              value: "1:00 PM",
            },
            {
              label: "1:30 PM",
              value: "1:30 PM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 PM",
              value: "2:30 PM",
            },
            {
              label: "3:00 PM",
              value: "3:00 PM",
            },
            {
              label: "3:30 PM",
              value: "3:30 PM",
            },
            {
              label: "4:00 PM",
              value: "4:00 PM",
            },
            {
              label: "4:30 PM",
              value: "4:30 PM",
            },
            {
              label: "5:00 PM",
              value: "5:00 PM",
            },
            {
              label: "5:30 PM",
              value: "5:30 PM",
            },
            {
              label: "6:00 PM",
              value: "6:00 PM",
            },
            {
              label: "6:30 PM",
              value: "6:30 PM",
            },
            {
              label: "7:00 PM",
              value: "7:00 PM",
            },
            {
              label: "7:30 PM",
              value: "7:30 PM",
            },
            {
              label: "8:00 PM",
              value: "8:00 PM",
            },
            {
              label: "8:30 PM",
              value: "8:30 PM",
            },
            {
              label: "9:00 PM",
              value: "9:00 PM",
            },
            {
              label: "9:30 PM",
              value: "9:30 PM",
            },
            {
              label: "10:00 PM",
              value: "10:00 PM",
            },
            {
              label: "10:30 PM",
              value: "10:30 PM",
            },
            {
              label: "11:00 PM",
              value: "11:00 PM",
            },
            {
              label: "11:30 PM",
              value: "11:30 PM",
            },
            {
              label: "Midnight",
              value: "Midnight",
            },
            {
              label: "12:30 AM",
              value: "12:30 AM",
            },
            {
              label: "1:00 AM",
              value: "1:00 AM",
            },
            {
              label: "1:30 AM",
              value: "1:30 AM",
            },
            {
              label: "2:00 AM",
              value: "2:00 AM",
            },
            {
              label: "2:30 AM",
              value: "2:30 AM",
            },

            {
              label: "3:00 AM",
              value: "3:00 AM",
            },
            {
              label: "3:30 AM",
              value: "3:30 AM",
            },
            {
              label: "4:00 AM",
              value: "4:00 AM",
            },
            {
              label: "4:30 AM",
              value: "4:30 AM",
            },
            {
              label: "5:00 AM",
              value: "5:00 AM",
            },
            {
              label: "5:30 AM",
              value: "5:30 AM",
            },
          ],
        },
      ],
    },
  ],
};

export let propertyAmenties = [
  {
    label: "When can guests check in?",
    data: [
      {
        label: "From",
        type: "select",
        name: "check_in",
        data: [
          {
            label: "Select the option",
            value: "",
            selected: true,
          },
          {
            label: "6:00 AM",
            value: "6:00 AM",
          },
          {
            label: "6:30 AM",
            value: "6:30 AM",
          },
          {
            label: "7:00 AM",
            value: "7:00 AM",
          },
          {
            label: "7:30 AM",
            value: "7:30 AM",
          },
          {
            label: "8:00 AM",
            value: "8:00 AM",
          },
          {
            label: "8:30 AM",
            value: "8:30 AM",
          },
          {
            label: "9:00 AM",
            value: "9:00 AM",
          },
          {
            label: "9:30 AM",
            value: "9:30 AM",
          },
          {
            label: "10:00 AM",
            value: "10:00 AM",
          },
          {
            label: "10:30 AM",
            value: "10:30 AM",
          },
          {
            label: "11:00 AM",
            value: "11:00 AM",
          },
          {
            label: "11:30 AM",
            value: "11:30 AM",
          },
          {
            label: "noon",
            value: "noon",
          },
          {
            label: "12:30 PM",
            value: "12:30 PM",
          },
          {
            label: "1:00 PM",
            value: "1:00 PM",
          },
          {
            label: "1:30 PM",
            value: "1:30 PM",
          },
          {
            label: "2:00 AM",
            value: "2:00 AM",
          },
          {
            label: "2:30 PM",
            value: "2:30 PM",
          },
          {
            label: "3:00 PM",
            value: "3:00 PM",
          },
          {
            label: "3:30 PM",
            value: "3:30 PM",
          },
          {
            label: "4:00 PM",
            value: "4:00 PM",
          },
          {
            label: "4:30 PM",
            value: "4:30 PM",
          },
          {
            label: "5:00 PM",
            value: "5:00 PM",
          },
          {
            label: "5:30 PM",
            value: "5:30 PM",
          },
          {
            label: "6:00 PM",
            value: "6:00 PM",
          },
          {
            label: "6:30 PM",
            value: "6:30 PM",
          },
          {
            label: "7:00 PM",
            value: "7:00 PM",
          },
          {
            label: "7:30 PM",
            value: "7:30 PM",
          },
          {
            label: "8:00 PM",
            value: "8:00 PM",
          },
          {
            label: "8:30 PM",
            value: "8:30 PM",
          },
          {
            label: "9:00 PM",
            value: "9:00 PM",
          },
          {
            label: "9:30 PM",
            value: "9:30 PM",
          },
          {
            label: "10:00 PM",
            value: "10:00 PM",
          },
          {
            label: "10:30 PM",
            value: "10:30 PM",
          },
          {
            label: "11:00 PM",
            value: "11:00 PM",
          },
          {
            label: "11:30 PM",
            value: "11:30 PM",
          },
          {
            label: "Midnight",
            value: "Midnight",
          },
          {
            label: "12:30 AM",
            value: "12:30 AM",
          },
          {
            label: "1:00 AM",
            value: "1:00 AM",
          },
          {
            label: "1:30 AM",
            value: "1:30 AM",
          },
          {
            label: "2:00 AM",
            value: "2:00 AM",
          },
          {
            label: "2:30 AM",
            value: "2:30 AM",
          },

          {
            label: "3:00 AM",
            value: "3:00 AM",
          },
          {
            label: "3:30 AM",
            value: "3:30 AM",
          },
          {
            label: "4:00 AM",
            value: "4:00 AM",
          },
          {
            label: "4:30 AM",
            value: "4:30 AM",
          },
          {
            label: "5:00 AM",
            value: "5:00 AM",
          },
          {
            label: "5:30 AM",
            value: "5:30 AM",
          },
        ],
      },
      {
        label: "To",
        type: "select",
        name: "check_out",
        data: [
          {
            label: "Select the option",
            value: "",
            selected: true,
          },
          {
            label: "6:00 AM",
            value: "6:00 AM",
          },
          {
            label: "6:30 AM",
            value: "6:30 AM",
          },
          {
            label: "7:00 AM",
            value: "7:00 AM",
          },
          {
            label: "7:30 AM",
            value: "7:30 AM",
          },
          {
            label: "8:00 AM",
            value: "8:00 AM",
          },
          {
            label: "8:30 AM",
            value: "8:30 AM",
          },
          {
            label: "9:00 AM",
            value: "9:00 AM",
          },
          {
            label: "9:30 AM",
            value: "9:30 AM",
          },
          {
            label: "10:00 AM",
            value: "10:00 AM",
          },
          {
            label: "10:30 AM",
            value: "10:30 AM",
          },
          {
            label: "11:00 AM",
            value: "11:00 AM",
          },
          {
            label: "11:30 AM",
            value: "11:30 AM",
          },
          {
            label: "noon",
            value: "noon",
          },
          {
            label: "12:30 PM",
            value: "12:30 PM",
          },
          {
            label: "1:00 PM",
            value: "1:00 PM",
          },
          {
            label: "1:30 PM",
            value: "1:30 PM",
          },
          {
            label: "2:00 AM",
            value: "2:00 AM",
          },
          {
            label: "2:30 PM",
            value: "2:30 PM",
          },
          {
            label: "3:00 PM",
            value: "3:00 PM",
          },
          {
            label: "3:30 PM",
            value: "3:30 PM",
          },
          {
            label: "4:00 PM",
            value: "4:00 PM",
          },
          {
            label: "4:30 PM",
            value: "4:30 PM",
          },
          {
            label: "5:00 PM",
            value: "5:00 PM",
          },
          {
            label: "5:30 PM",
            value: "5:30 PM",
          },
          {
            label: "6:00 PM",
            value: "6:00 PM",
          },
          {
            label: "6:30 PM",
            value: "6:30 PM",
          },
          {
            label: "7:00 PM",
            value: "7:00 PM",
          },
          {
            label: "7:30 PM",
            value: "7:30 PM",
          },
          {
            label: "8:00 PM",
            value: "8:00 PM",
          },
          {
            label: "8:30 PM",
            value: "8:30 PM",
          },
          {
            label: "9:00 PM",
            value: "9:00 PM",
          },
          {
            label: "9:30 PM",
            value: "9:30 PM",
          },
          {
            label: "10:00 PM",
            value: "10:00 PM",
          },
          {
            label: "10:30 PM",
            value: "10:30 PM",
          },
          {
            label: "11:00 PM",
            value: "11:00 PM",
          },
          {
            label: "11:30 PM",
            value: "11:30 PM",
          },
          {
            label: "Midnight",
            value: "Midnight",
          },
          {
            label: "12:30 AM",
            value: "12:30 AM",
          },
          {
            label: "1:00 AM",
            value: "1:00 AM",
          },
          {
            label: "1:30 AM",
            value: "1:30 AM",
          },
          {
            label: "2:00 AM",
            value: "2:00 AM",
          },
          {
            label: "2:30 AM",
            value: "2:30 AM",
          },

          {
            label: "3:00 AM",
            value: "3:00 AM",
          },
          {
            label: "3:30 AM",
            value: "3:30 AM",
          },
          {
            label: "4:00 AM",
            value: "4:00 AM",
          },
          {
            label: "4:30 AM",
            value: "4:30 AM",
          },
          {
            label: "5:00 AM",
            value: "5:00 AM",
          },
          {
            label: "5:30 AM",
            value: "5:30 AM",
          },
        ],
      },
    ],
  },
  {
    label: "Is late check-in available?",
    data: [
      {
        type: "radio",
        name: "lateCheckAvailable",
        data: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
      },
    ],
  },
  {
    label: "Age registration",
    name: "minimumAge",
    data: [
      {
        label: "Minimum check-in age",
        name: "minimumAge",
        type: "select",
        data: [
          {
            label: "Select the option",
            value: "",
            selected: true,
          },
          {
            label: "15",
            value: 15,
          },
          {
            label: "16",
            value: 16,
          },
          {
            label: "17",
            value: 17,
          },
          {
            label: "18",
            value: 18,
          },
          {
            label: "19",
            value: 19,
          },
          {
            label: "20",
            value: 20,
          },
          {
            label: "21",
            value: 21,
          },
          {
            label: "22",
            value: 22,
          },
          {
            label: "23",
            value: 23,
          },
          {
            label: "24",
            value: 24,
          },
          {
            label: "25",
            value: 25,
          },
        ],
      },
    ],
  },
  // {
  //   label: "Do you offer internet?",
  //   data: [
  //     {
  //       type: "radio",
  //       name: "internet",
  //       data: [
  //         {
  //           label: "Yes",
  //           value: "yes",
  //         },
  //         {
  //           label: "No",
  //           value: "no",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: "Do you offer parking?",
  //   data: [
  //     {
  //       type: "radio",
  //       name: "internet",
  //       data: [
  //         {
  //           label: "Yes",
  //           value: "yes",
  //         },
  //         {
  //           label: "No",
  //           value: "no",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Popular facilities and services",
  },
  {
    label: "Do your guests have pool access?",
    data: [
      {
        type: "radio",
        name: "pool_access",
        data: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
      },
    ],
  },
  // {
  //   label: "Do you allow pets?",
  //   name: ""
  //   data: [
  //     {
  //       type: "radio",
  //       name: "allow_pet",
  //       data: [
  //         {
  //           label: "Yes",
  //           value: "yes",
  //         },
  //         {
  //           label: "No",
  //           value: "no",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Do you have accessibility features at your property?",
    name: "accessibility_feature",
    data: [
      {
        type: "radio",
        name: "accessibility_feature",
        data: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
      },
    ],
  },
  {
    label: "Do you have outdoor recreation areas?",
    data: [
      {
        type: "radio",
        name: "outdoor_recreation_areas",
        data: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
      },
    ],
  },
];

export const bathroomAmenties = [
  {
    label: "Does this room have a bath or shower?",
    width: "20rem",
    labelId: "bath_shower_label",
    id: "bath_shower_select",
    name: "bath_shower",
    type: "select",
    valueLabel: "label",
    valueName: "value",
    option: [
      { label: "Bathtub Only", value: "bathtubOnly" },
      { label: "Bathtub or shower", value: "bathtubOrShower" },
      { label: "Seperate bathtub or shower", value: "seperateBathtubOrShower" },
      { label: "Shower/tub combination", value: "showerTubCombination" },
    ],
  },
  {
    label: "Does this room have these essentials?",
    name: "bathroom_accessories",
    type: "checkbox",
    option: [
      { name: "toiletries", label: "Free toiletries" },
      { name: "soap", label: "Soap" },
      { name: "shampoo", label: "Shampoo" },
      { name: "toiletPaper", label: "Toilet paper" },
    ],
  },
  {
    label: "Does this room provide towels?",
    name: "room_towels",
    type: "radio",
    option: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
];
export const hotelAmenties = [
  {
    label: "Parking",
    image: "/hotelAmenties/parking.png", // Path relative to the public/ folder
    name: "parking",
  },
  {
    label: "Gym",
    image: "/hotelAmenties/gym.png", // Path relative to the public/ folder
    name: "gym",
  },
  {
    label: "Hot tub",
    image: "/hotelAmenties/hot_tub.png", // Path relative to the public/ folder
    name: "hottub",
  },
  {
    label: "Outdoor space",
    image: "/hotelAmenties/outdoor_space.png", // Path relative to the public/ folder
    name: "outdoor_space",
  },
  {
    label: "Restaurent",
    image: "/hotelAmenties/restaurnet.png", // Path relative to the public/ folder
    name: "restaurent",
  },
  {
    label: "Pet Friendly",
    image: "/hotelAmenties/pet_friendly.png", // Path relative to the public/ folder
    name: "pet_friendly",
  },
  {
    label: "Wifi",
    image: "/hotelAmenties/wifi.png", // Path relative to the public/ folder
    name: "internet",
  },
  {
    label: "Spa",
    image: "/hotelAmenties/spa.png", // Path relative to the public/ folder
    name: "spa",
  },
  {
    label: "Water Park",
    image: "/hotelAmenties/water_park.png", // Path relative to the public/ folder
    name: "water_park",
  },
  {
    label: "Air Condition",
    image: "/hotelAmenties/air_conditioning.png", // Path relative to the public/ folder
    name: "air_condition",
  },
  {
    label: "Kitchen",
    image: "/hotelAmenties/kitchen.png", // Path relative to the public/ folder
    name: "kitchen",
  },
];

export const roomEssentialData = [
  {
    label: "Free toiletries",
    name: "toiletries",
    value: "toiletries",
  },
  {
    label: "Soap",
    name: "soap",
    value: "soap",
  },
  {
    label: "Shampoo",
    name: "shampoo",
    value: "shampoo",
  },
  {
    label: "Toilet paper",
    name: "toiletPaper",
    value: "toiletPaper",
  },
];

export const roomAmenties = [
  {
    label: "Free Wifi",
    value: "freeWifi",
    image: "/roomAmenties/wifi.png",
  },
  {
    label: "Private Work Place",
    value: "privateWorkPlace",
    image: "/roomAmenties/private_work_place.png",
  },
  {
    label: "Smart Key",
    value: "smartKey",
    image: "/roomAmenties/smart_key.png",
  },
  // {
  //   lable: "Big TV",
  //   value: "bigTv",
  //   image: "/roomAmenties/bigTv.png"
  // },
  {
    label: "Big TV",
    value: "bigTv",
    image: "/roomAmenties/bigTv.png",
  },
  {
    label: "Swimming Pool",
    value: "swimmingPool",
    image: "/roomAmenties/swimming_pool.png",
  },
  {
    label: "Coffee Maker",
    value: "coffeeMaker",
    image: "/roomAmenties/coffe_maker.png",
  },
  {
    label: "Parking available",
    value: "parkingAvailable",
    image: "/roomAmenties/parking_available.png",
  },
  {
    label: "Top rated in area",
    value: "topRatedInArea",
    image: "/roomAmenties/top_rated.png",
  },
  {
    label: "Air Conditioning",
    value: "airConditioning",
    image: "/roomAmenties/air_conditioning.png",
  },
];

export const roomViewLocation = [
  {
    label: "Bay View",
    value: "bayView",
  },
  {
    label: "Beach View",
    value: "beachView",
  },
  {
    label: "Canal View",
    value: "canalView",
  },
  {
    label: "City View",
    value: "cityView",
  },
  {
    label: "Countyard View",
    value: "countyardView",
  },
  {
    label: "Desert View",
    value: "desertView",
  },
  {
    label: "Garden View",
    value: "gardenView",
  },
  {
    label: "Golf View",
    value: "golfView",
  },
  {
    label: "Harbour View",
    value: "harbourView",
  },
  {
    label: "Hill View",
    value: "hillView",
  },
  {
    label: "Lagoon View",
    value: "lagoonView",
  },
  {
    label: "Lake View",
    value: "lakeView",
  },
  {
    label: "Partial Lake View",
    value: "partialLakeView",
  },
  {
    label: "Marina View",
    value: "marinaView",
  },
  {
    label: "Mountain view",
    value: "mountainView",
  },
  {
    label: "Ocean view",
    value: "oceanView",
  },
  {
    label: "Partial ocean view",
    value: "partialOceanView",
  },
  {
    label: "Partial sea view",
    value: "seaView",
  },
  {
    label: "Park view",
    value: "parkView",
  },
  {
    label: "Pool view",
    value: "poolView",
  },
  {
    label: "Partial sea view",
    value: "seaView",
  },
  {
    label: "Resort view",
    value: "resortView",
  },
  {
    label: "River view",
    value: "riverView",
  },
  {
    label: "Valley view",
    value: "valleyView",
  },
  {
    label: "Vineyard view",
    value: "vineyardView",
  },
  {
    label: "Water view",
    value: "waterView",
  },
];

export const adminSidebarData = [
  {
    label: "Dashboard",
    value: "dashboard",
    imgSrc: "/images/dashboard/Dashboard.svg"
  },
  {
    label: "Agent",
    value: "agent",
    linking: "/admin/agentlist",
    imgSrc: "/images/dashboard/Rates & Availability.svg"
  },
  {
    label: "Users",
    value: "userlist",
    linking: "/admin/userlist",
    imgSrc: "/images/dashboard/Rates & Availability.svg"
  }
]

export const sidebarData = [
  {
    label: "Dashboard",
    value: "/partner/dashboard",
    imgSrc: "/images/dashboard/Dashboard.svg"
  },
  {
    label: "Rates & availability",
    value: "rates",
    linking: "/partner/rates",
    imgSrc: "/images/dashboard/Rates & Availability.svg"

  },
  {
    label: "Reservation",
    value: "reservation",
    imgSrc: "/images/dashboard/Reservations.svg",
    linking: "/partner/reservation"
  },
  {
    label: "Cancellation Policy",
    value: "cancellation_policy",
    imgSrc: "/images/dashboard/Cancellation Policy.svg"
  },
  {
    label: "Property details",
    value: "property_details",
    imgSrc: "/images/dashboard/Property details.svg",
    linking: "/partner/property_details"
  },
  {
    label: "Payment",
    value: "payment",
    imgSrc: "/images/dashboard/Cancellation Policy.svg"
  },
  {
    label: "Administration",
    value: "administration",
    imgSrc: "/images/dashboard/Administration.svg"
  },
]
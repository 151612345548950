import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_URL } from '../../network';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_API_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState())
      const token = getState()?.auth?.token;
      console.log("token", token)
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
          url: `userlistApi`,
          method: "GET"
      }),
      transformResponse: (response) => response.data
    }),
  }),
});
export const { useGetUsersQuery } = usersApi;

import { Box, Select, TextField, Typography } from "@mui/material";
import SelectOptionComponent from "../../../Tools/SelectOptionComponent";
import { useEffect } from "react";

const StartWithBasicRoomComponent = ({
  roomTypedata,
  roomClassData,
  state,
  setState,
  initialValues,
  errors,
  formik,
}) => {
  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  console.log("formik.values.room_types", formik.values)
  return (
    <Box>
      <Typography fontWeight={"500"} variant="h3">
        {"Set up your room"}
      </Typography>
      <Typography variant="h6" fontWeight={"500"}>
        {"Start with the basics"}
      </Typography>
      <Typography variant="p" gap={2}>
        {
          "Room types are the basic description of a room, like if it's a single or double. If you add a room class, make sure to use it consistently across all rooms."
        }
      </Typography>
      <Box display="flex" gap={1}>
        <SelectOptionComponent
          label={"Room Type"}
          labelId={"label-room-type-id"}
          id={"room-type-select"}
          option={roomTypedata}
          value={formik.values.room_type}
          defaultValue={state.room_type}
          valueLabel={"name"}
          valueName={"id"}
          name={"room_type"}
          errors={errors}
          style={{ width: "20rem" }}
          handleChange={(e) => {
            handleChange(e);
            formik?.handleChange(e);
          }}
        />
        <SelectOptionComponent
          label={"Room Class"}
          labelId={"label-room-class-id"}
          id={"room-class-select"}
          option={roomClassData}
          value={formik.values.room_class}
          defaultValue={initialValues.room_class}
          valueLabel={"name"}
          valueName={"id"}
          errors={errors}
          name={"room_class"}
          style={{ width: "20rem" }}
          handleChange={(e) => {
            handleChange(e);
            formik?.handleChange(e);
          }}
        />
      </Box>
      <br></br>
      {state?.room_type && (
        <Box display={"flex"} gap={1}>
          <TextField
            type="number"
            id="number_of_bedroom"
            label={"Number of bedrooms"}
            name="number_of_bedroom"
            onChange={(e) => {
              formik.handleChange(e);
              handleChange(e);
            }}
            error={
              formik.touched.number_of_bedroom &&
              Boolean(formik.errors.number_of_bedroom)
            }
            helperText={
              formik.touched.number_of_bedroom &&
              formik.errors.number_of_bedroom
            }
            defaultValue={initialValues?.number_of_bedroom}
            variant="outlined"
          />
          <TextField
            type="number"
            id="number_of_living_space"
            label={"Number of living space"}
            name="number_of_living_space"
            error={
              formik.touched.number_of_living_space &&
              Boolean(formik.errors.number_of_living_space)
            }
            helperText={
              formik.touched.number_of_living_space &&
              formik.errors.number_of_living_space
            }
            onChange={(e) => {
              formik.handleChange(e);
              handleChange(e);
            }}
            defaultValue={initialValues?.number_of_living_space}
            variant="outlined"
          />
        </Box>
      )}
      <br></br>
      <Box>
        <Typography>{"What's the smoking policy?"}</Typography>
        <SelectOptionComponent
          label={"Smoking Policy"}
          labelId={"label-room-type-id"}
          id={"room-type-select"}
          option={[
            { label: "Smoking", value: "smoking" },
            { label: "Non Smoking", value: "non smoking" },
          ]}
          valueLabel={"label"}
          value={formik.values.smoking_area}
          defaultValue={initialValues.smoking_area}
          valueName={"value"}
          name={"smoking_area"}
          errors={errors}
          style={{ width: "20rem" }}
          handleChange={(e) => {
            formik.handleChange(e);
            handleChange(e);
          }}
        />
      </Box>
      <br></br>
      <Box>
        <Typography>{"How many of these rooms do you have?"}</Typography>
        <TextField
          type="number"
          id="number_of_room"
          label={"Number of rooms"}
          name="number_of_rooms"
          value={formik.values.number_of_rooms}
          error={
            formik.touched.number_of_rooms &&
            Boolean(formik.errors.number_of_rooms)
          }
          helperText={
            formik.touched.number_of_rooms && formik.errors.number_of_rooms
          }
          onChange={(e) => {
            formik.handleChange(e);
            handleChange(e);
          }}
          defaultValue={initialValues.number_of_bedroom}
          variant="outlined"
        />
      </Box>
      <div></div>
    </Box>
  );
};

export default StartWithBasicRoomComponent;

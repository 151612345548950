import { Link } from "react-router-dom";

const ListItem = ({ imgSrc, label, linking, activeTab, onClick }) => {
  return (
    <li onClick={onClick} className={activeTab ? "active" : ""}>
      <Link to={linking}>
        <img src={imgSrc} /> <span> {label}</span>
      </Link>
    </li>
  );
};

export default ListItem;

import { Box, Typography } from "@mui/material";

const AmenitiesBox = ({ imgWidth = 40, imgUrl, textStyle, label, parentStyle, onClick }) => {
    return (
        <Box
            display="flex"
            style={parentStyle}
            alignItems="center"
            gap={2}
            padding={1.5}
            border={1}
            borderRadius={2}
            onClick={onClick}
            borderColor="grey.300"
            sx={{
                transition: "box-shadow 0.3s ease",
                "&:hover": {
                    boxShadow: 3, // Elevates the box slightly on hover
                },
            }}
        >
            <Box
                component="img"
                src={imgUrl}
                alt={label}
                width={imgWidth}
                sx={{
                    borderRadius: "50%", // Circular images for a better visual
                    objectFit: "cover",
                }}
            />
            <Typography
                variant="body1"
                style={textStyle}
                sx={{
                    fontWeight: "bold",
                    color: "text.primary",
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};

export default AmenitiesBox;

import { Button, Typography } from "@mui/material";
import TaxBox from "./taxBox";
import { useFormik } from "formik";
import { taxesSchema } from "../../../Schema/propertyRegistationSchema";
import { useEffect } from "react";
import {
  useCreateTaxMutation,
  useGetPropertyDataQuery,
} from "../../../Api/hotelApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PropertyTaxes = ({ data, error, isLoading, setIsActive, refetch }) => {
  console.log("daaaaaataaaaa->", data);
  let initialValues = {
    legal_name: data.legal_name,
    property_register: data.property_register,
    vat_gst_id: data.vat_gst_id,
  };
  const navigate = useNavigate();
  const [createTax] = useCreateTaxMutation();

  const formik = useFormik({
    initialValues,
    validationSchema: taxesSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // console.log("data", data);
        values.property_id = data?.id;
        const response = await createTax(values).unwrap();
        if (response.status) {
          toast.success(response.message);
          alert(response.message);
          setIsActive('point_of_interest');
          refetch();
          // navigate('/point_of_interest');
        }
      } catch (e) {
        toast.error(e.response.data.message);
        alert(e.response.data.message);
        console.log("Error", e);
      }
      console.log("valuess--->", values);
    },
  });

  useEffect(() => {
     formik.setValues({
      legal_name: data.legal_name,
      property_register: data.property_register,
      vat_gst_id: data.vat_gst_id
     });
  }, [data])

  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              variant="p"
            >
              {"Taxes"}
            </Typography>
            <br></br>
            <Typography width={"20"} variant="p">
              {
                "To get your property listed faster and start sending guests your way, we need to collect your property's legal name and tax information."
              }
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TaxBox formik={formik} handleChange={formik.handleChange} />
              <Button type="submit" className="btn btn-primary">
                {"Submit"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyTaxes;

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  colors,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { acceptedPaymentForms, requireAnyDeposits } from "../../../data/Hotel";
import DepositCheckbox from "./depositCheckbox";

const RequireAnyDeposit = () => {
  const [active, setActive] = React.useState({
    deposit: "",
  });

  const handleDeposit = (depositStatus) => {
    setActive({ deposit: depositStatus });
  };

  //  useEffect(() => {
  //    console.log("active", active);
  //  }, [active]);

  const style = {
    deposit: {
      background: "blue",
      color: "#fff",
    },
    depositCheckbox: {
      display: "grid",
    },
  };
  console.log("requireAnyDeposits.length", requireAnyDeposits);

  return (
    <>
      <Card sx={{ ml: "5px", padding: "16px" }}>
        <React.Fragment>
          <CardContent>
            <Typography variant="h5" component="div">
              {"Do you require any deposits?"}
            </Typography>
            <Button
              style={active.deposit == "yes" ? style.deposit : {}}
              onClick={() => handleDeposit("yes")}
            >
              Yes
            </Button>
            <Button
              style={active.deposit == "no" ? style.deposit : {}}
              onClick={() => handleDeposit("no")}
            >
              No
            </Button>
            {/* Deposit CheckBox */}
            {active.deposit == "yes" && (
              <DepositCheckbox
                style={style}
                requireAnyDeposits={requireAnyDeposits}
              />
            )}
            {/* End Deposit CheckBox */}
            <div>
              <label>{"Forms of payment accepted for incidentals"}</label>
              <Select
                name="payment_accept_incidentals"
                defaultValue={"Cash, credit cards, debit cards"}
                fullWidth
              >
                {acceptedPaymentForms.map((item) => {
                  return <MenuItem value={item.label}>{item.label}</MenuItem>;
                })}
              </Select>
            </div>
          </CardContent>
        </React.Fragment>
      </Card>
    </>
  );
};

export default RequireAnyDeposit;

import React from "react";
import "../User/index.css";
// import { useGetProductQuery } from "../../../process/products/productApi";

// const apiUrl = process.env.REACT_APP_API_URL;
const Signup = () => {
//   const { id } = useParams();
//   const { data, isLoading, error } = useGetProductQuery({id});
//   console.log("data",data);
  return (
    <div class="user-dasboard-bg">
        <div class="container-fluid full-height">
            <div class="row full-height-screen">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-0">
                    <div class="image-section">
                        <img src="./assets/images/user-dasboard/tree-travel-background-blue-water.png" alt=""/>
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-0">
                    <div class="logo-travel">
                        <img src="./assets/images/user-dasboard/GetMyHotels_logo-01 (1).png" class="logo-get-my-hotel"/>
                    </div>

                    <form class="form">
                        <div class="Forgot-passwoard-code">
                            <h2>Register </h2>
                            <p>Hey, Please fill in your credentials to access your account</p>
                        </div>

                        <div class="row g-3">
                            <div class="col">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="First name"
                                    aria-label="First name"/>
                            </div>
                            <div class="col">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
                            </div>
                        </div>
                        <div class="flex-column">
                            <label>Phone Number</label>
                        </div>
                        <div class="inputForm">
                            <input type="text" class="input" placeholder="Phone Number"/>
                        </div>
                        <div class="flex-column">
                            <label>New Password</label>
                        </div>
                        <div class="inputForm">
                            <input type="text" class="input" placeholder="Set Password"/>
                        </div>

                        <div class="flex-column">
                            <label>Confirm Password</label>
                        </div>
                        <div class="inputForm">
                            <input type="text" class="input" placeholder="Confirm Password"/>
                        </div>

                        <button class="button-submit">Next</button>

                    </form>
                </div>
            </div>
        </div>
    </div>
  );
};
export default Signup;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import demoData from "../data/Hotel/demoData.json"; // Import your demo JSON
import { apiUrl } from "../Helper/url";
import { authorizationCommom } from "../Helper/service";
// import { error } from "ajv/dist/vocabularies/applicator/dependencies";

const isDemo = true; // Toggle this based on the environment
const apiBaseUrl = "http://localhost:3000/api";
const demoBaseQuery = async ({ url }) => {
  // Map URLs to the demo JSON data

  const dataMap = {
    "auth/room-type": demoData.roomType,
    "auth/room-class": demoData.roomClass,
    "/selectedRooms": demoData.selectedRooms,
  };

  return {
    data: dataMap[url] || { error: "Endpoint not found" },
  };
};

export const hotelApi = createApi({
  reducerPath: "hotelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.token ? getState().auth.token : localStorage.getItem('token');
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    propertyType: builder.query({
      query: () => ({
        url: "getPropertyType",
        headers: {
          authorization: `bearer ${localStorage.getItem("token")}`,
        },
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    roomType: builder.query({
      query: () => ({ url: "getRoomType" }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    roomClass: builder.query({
      query: () => ({ url: "getRoomClass" }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
      // onError
    }),
    sendOtp: builder.mutation({
      query: (body) => ({
        url: `/selectedRooms`,
        method: "POST",
        body: body,
      }),
    }),
    getPropertyData: builder.query({
      query: (id) => ({
        url: `getPropertyData/${id}`, // Assuming `id` is part of the endpoint
        method: "GET", // Default for fetching data
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    propertyRegister: builder.mutation({
      query: (body) => ({
        url: "/registration_step1",
        method: "POST",
        body: body,
      }),
    }),
    propertyAmentiesPropertySave: builder.mutation({
      query: (body) => ({
        url: "/save_amenties_property",
        method: "POST",
        body,
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    roomSave: builder.mutation({
      query: (body) => ({
        url: "/room_create",
        method: "POST",
        body,
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    getRoomByProperty: builder.query({
      query: (id) => ({
        url: `getRoomByProperty/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    uploadPhoto: builder.mutation({
      query: (body) => ({
        url: `/uploadPhotos`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    getRoom: builder.query({
      query: (id) => ({
        url: `getroom/${id}`,
        method: "get",
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      },
    }),
    createTax: builder.mutation({
      query: (body) => ({
        url: "createTax",
        method: "post",
        body,
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      }
    }),
    updatePointOfInterest: builder.mutation({
      query: (body) => ({
        url: 'updatePointOfInterest',
        method: 'post',
        body
      }),
      transformErrorResponse: (error) => {
        authorizationCommom(error);
      }
    }),

    // delete rom
    deleteRoom: builder.mutation({
      query: (id) => ({
        url: `deleteRoom/${id}`,
        method: "DELETE",
      }),
    }),
    
    // verifyOtp: builder.mutation({
    //   query: (body) => ({
    //     url: `auth/verify-otp`,
    //     method: "POST",
    //     body: body,
    //   }),
    // }),
    // registerUser: builder.mutation({
    //   query: (body) => ({
    //     url: `auth/register`,
    //     method: "POST",
    //     body: body,
    //   }),
    // }),
  }),
});

export const {
  useRoomClassQuery,
  useRoomTypeQuery,
  useSendOtpMutation,
  usePropertyTypeQuery,
  usePropertyRegisterMutation,
  usePropertyAmentiesPropertySaveMutation,
  useGetPropertyDataQuery,
  useRoomSaveMutation,
  useUploadPhotoMutation,
  useGetRoomByPropertyQuery,
  useGetRoomQuery,
  useCreateTaxMutation,
  useUpdatePointOfInterestMutation,
  // Room
  useDeleteRoomMutation
} = hotelApi;

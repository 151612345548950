import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../Helper/url';


export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
    endpoints: (builder) => ({
    //   roomType: builder.query({
    //     query: () => ({ url: `auth/room-type` }),
    //   }),
      loginIn: builder.mutation({
        query: (body) => ({
          url: '/loginAccount',
          method: "POST",
          body: body  
        })
      }),
      signUp: builder.mutation({
        query: (body) => ({
          url: `/register-account`,
          method: "POST",
          body: body,
        }),
      }),
      // verifyOtp: builder.mutation({
      //   query: (body) => ({
      //     url: `auth/verify-otp`,
      //     method: "POST",
      //     body: body,
      //   }),
      // }),
      // registerUser: builder.mutation({
      //   query: (body) => ({
      //     url: `auth/register`,
      //     method: "POST",
      //     body: body,
      //   }),
      // }),
    }),
  });

  export const { useSignUpMutation, useLoginInMutation } = authApi;
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const HotelBasicInformation = ({
  handleChange,
  errors,
  formik,
  propertyData,
}) => {
  return (
    <>
      <FormControl sx={{ m: 1, ml: 0.1, mt: 2, display: "inline" }} fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {"Property Type"}
        </InputLabel>
        <NativeSelect
          //   defaultValue={30}
          aria-label=""
          error={formik.touched.property_type && Boolean(errors.property_type)}
          onChange={handleChange}
          style={{ width: "25rem" }}
          value={formik.values?.property_type}
          name="property_type"
          inputProps={{
            name: "property_type",
            id: "uncontrolled-native",
          }}
        >
          <option value={""}>Select property type</option>
          {propertyData?.data?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </NativeSelect>
        <TextField
          sx={{ width: "25rem", ml: 4 }}
          name="number_of_room"
          type="number"
          value={formik.values.number_of_room}
          onChange={handleChange}
          error={
            formik.touched.number_of_room && Boolean(errors.number_of_room)
          }
          helperText={
            formik.touched.number_of_room && formik.errors.number_of_room
          }
          label="Number of rooms/units"
          variant="standard"
        ></TextField>
      </FormControl>
      <FormControl
        sx={{
          gap: 4,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextField
          name="legal_name"
          label="Legal name of your property"
          value={formik.values.legal_name}
          onChange={handleChange}
          error={formik.touched.legal_name && Boolean(errors.legal_name)}
          variant="standard"
          helperText={formik.touched.legal_name && formik.errors.legal_name}
          sx={{ width: "25rem" }}
        />
        <NativeSelect
          aria-label=""
          sx={{ width: "25rem" }}
          error={formik.touched.currency && Boolean(errors.currency)}
          helperText={formik.touched.currency && formik.errors.currency}
          onChange={handleChange}
          value={formik.values.currency}
          name="currency"
          inputProps={{
            name: "currency",
            id: "property-currency",
          }}
        >
          <option value={""}>Select Currency</option>
          <option value={"rupee"}>Rupee</option>
          <option value={"dollar"}>Dollar</option>
        </NativeSelect>
      </FormControl>
      <FormControl sx={{ mt: 3 }}>
        <FormLabel id="channel-manager-label">
          {"Does this property work with a channel manager?"}
        </FormLabel>
        <RadioGroup
          aria-labelledby="channel-manager-label"
          name="channel_manager"
          onChange={handleChange}
          value={formik.values.channel_manager}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {errors.channel_manager && (
        <FormHelperText style={{ color: "red" }}>{"Required"}</FormHelperText>
      )}
      <br></br>
      <FormControl sx={{ mt: 3 }}>
        <FormLabel id="part-of-chain-label">
          {"Is this property part of a chain?"}
        </FormLabel>
        <RadioGroup
          aria-labelledby="part-of-chain-label"
          value={formik.values.part_of_chain}
          onChange={handleChange}
          //   defaultValue="female"
          name="part_of_chain"
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        {errors.part_of_chain && (
          <FormHelperText style={{ color: "red" }}>{"Required"}</FormHelperText>
        )}
      </FormControl>
      {/* <FormControl fullWidth></FormControl> */}
    </>
  );
};

export default HotelBasicInformation;

import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const Discover = ({state}) => {
  return (
    <section class="dicover-history best-facalities">
    <div class="container">
      <div class="row">
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-sm-12 col-md-6">
          <div class="image">
            <img src="./assets/images/our-history-image.png"/>
          </div>
        </div>
        <div class="col-xxl-5 col-xl-5 col-lg-5 col-sm-12 col-md-6">
          <div class="content">
            <h3> Discover Our
              <span class="additional-color-blue"> History</span>
            </h3>
            <p>Welcome to our Hotel Booking platform, where your dream vacation begins with just a few clicks. We are
              dedicated to
              helping you find the perfect accommodation, whether you're looking for a luxury resort, a cozy boutique
              hotel, or a
              budget-friendly stay. With a wide range of options, easy booking processes, and personalized
              recommendations...</p>
            <button>Discover More</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Discover;

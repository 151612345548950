import React from "react";
import "../User/index.css";
import { useNavigate } from "react-router-dom";
// import { useGetProductQuery } from "../../../process/products/productApi";

// const apiUrl = process.env.REACT_APP_API_URL;
const SignInUser = () => {
    const navigate = useNavigate();

  return (
<div class="user-dasboard-bg">
<div class="container-fluid full-height">
    <div class="row full-height-screen">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-0">
            <div class="image-section">
                <img src="./assets/images/user-dasboard/tree-travel-background-blue-water.png" alt=""/>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-0">
            <div class="logo-travel">
                <img src="./assets/images/user-dasboard/GetMyHotels_logo-01 (1).png" class="logo-get-my-hotel"/>
            </div>

            <form class="form">
                <div class="log-in-account">
                    <h2>Log in to your account</h2>
                    <p>Welcome back! Please enter your details.</p>
                </div>
                <div class="flex-column">
                    <label>Email <span>*</span></label>
                </div>
                <div class="inputForm">
                    <input type="text" class="input" placeholder="Enter your email"/>
                </div>

                <div class="flex-column">
                    <label>Password <span>*</span></label>
                </div>
                <div class="inputForm">

                    <input type="password" class="input" placeholder="Enter your Password"/>
                    
                </div>
                <div class="flex-row Forgot-passwoard">
                    <div>
                        <input type="checkbox"/>
                        <label>Remember for 30 days</label>
                    </div>
                    <span class="span">Forgot password</span>
                </div>
                <button class="button-submit">Sign In</button>
                <div class="flex-row sign-in-text">
                    <button class="btn google">
                        <span class="span">Sign in with Google</span>
                        </button>
                </div>
                <div class="flex-row sign-in-text">
                    <button class="btn google">
                        <span class="span">Continue With Facebook</span>
                        </button>
                </div>
                <p class="span-text">Don’t have an account? <span class="sign-up" onClick={()=>navigate('/signup')}> Sign up </span> </p>
            </form>
        </div>
    </div>
</div>
</div>)
};
export default SignInUser;
import React from "react";
import { adminSidebarData, sidebarData } from "../../data/Hotel";
import ListItem from "../../Tools/ListItem";
import { useNavigate } from "react-router-dom";

const PartnerSidebar = () => {
    const [isActive, setIsActive] = React.useState('dashboard');
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/partner/login');
    }
    return (
        <nav class="side-nav" id="sidebar">
            <div class="sidebar-header">
                <img src="/images/logo-get.svg" class="logo-img" />
            </div>
            <ul class="first-header-ul">
                {user.role_id === 1 && adminSidebarData && adminSidebarData.length > 0 && adminSidebarData.map((item) => (
                      <ListItem
                         activeTab={isActive===item.value}
                         label={item.label}
                         imgSrc={item.imgSrc}
                         linking={item.linking}
                         onClick={() => setIsActive(item.value)}
                      />
                ))}
                {user.role_id === 2 && sidebarData && sidebarData.length > 0 && sidebarData.map((item) => (
                    <ListItem
                       activeTab={isActive===item.value}
                       label={item.label}
                       imgSrc={item.imgSrc}
                       linking={item.linking}
                       onClick={() => {setIsActive(item.value)}}
                    />
                ))}
            </ul>
            <ul>
                <ListItem  label={"LogOut"} onClick={handleLogout} imgSrc={"/images/dashboard/SignOut.svg"} />
            </ul>
        </nav>
    )
}

export default PartnerSidebar;
import React, { useState } from "react";
import DateRangePickerFix from "../../../../Tools/DateRangePickerFix";
const Location = () => {
  const [open,setOpen]=useState(false);
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  const handleSelect=(ranges)=>{
    console.log("<==========ranges=========>",ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  return (<section class="upper-section">
    <div class="container">
      <ul class="nav nav-pills mb-3 input-tabs-wrapepr" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
            type="button" role="tab" aria-controls="pills-home" aria-selected="true"><span><i
                class="bi bi-circle-fill fill-bi-circle fill-bi-circle-icon"></i> </span><span class="tab-text-home">
              Stays </span></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
            type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><span
              class="tab-text-home">Cars </span></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
            type="button" role="tab" aria-controls="pills-contact" aria-selected="false"><span
              class="tab-text-home">Flights</span></button>
        </li>
        <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-package-tab" data-bs-toggle="pill" data-bs-target="#pills-package"
          type="button" role="tab" aria-controls="pills-package" aria-selected="false"><span
            class="tab-text-home">Packages </span></button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="tab-listing-data">
            <div class="location-card display-wrap">
              <div class="left">
                <img src="./assets/images/OBJECTS.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Location</h6>
                <input type="text" placeholder="Were are you going"/>
              </div>
            </div>
            <div class="calendar-card display-wrap">
              <div class="left">
                <img src="./assets/images/calendar-icon.svg"  onClick={() => setOpen(!open)}/>
              </div>
              <div class="right">
              <DateRangePickerFix val={open} onClose={() => setOpen(false)}/>
                {/* <h6 class="padding-left">Sep 24 - Sep 29</h6>
                <input type="date" id="date" placeholder="Check in - Check out"/> */}
              </div>
            </div>
              
           
            <div class=" guest-card display-wrap">
              <div class="left">
                <img src="./assets/images/profile.svg"/>
              </div>
              <div class="middle middle-span">
                <div>
                  <h6>Guests</h6>
                  <p class="mb-0"><span>2 Guest</span> <span>1 room</span></p>
                </div>
                <div class="right icon-search-bg">
                  <div class="search-icon-bi">
                    <i class="bi bi-search"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="tab-listing-data">
            <div class="location-card display-wrap">
              <div class="left">
                <img src="./assets/images/OBJECTS.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Location</h6>
                <input type="text" placeholder="Were are you going"/>
              </div>
            </div>
            <div class="calendar-card display-wrap">
              <div class="left">
                <img src="./assets/images/calendar-icon.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Sep 24 - Sep 29</h6>
                <input type="date" id="date" placeholder="Check in - Check out"/>
              </div>
            </div>
            <div class=" guest-card display-wrap">
              <div class="left">
                <img src="./assets/images/profile.svg"/>
              </div>
              <div class="middle middle-span">
                <div>
                  <h6>Guests</h6>
                  <p class="mb-0"><span>2 Guest</span> <span>1 room</span></p>
                </div>
                <div class="right icon-search-bg">
                  <div class="search-icon-bi">
                    <i class="bi bi-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="tab-listing-data">
            <div class="location-card display-wrap">
              <div class="left">
                <img src="./assets/images/OBJECTS.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Location</h6>
                <input type="text" placeholder="Were are you going"/>
              </div>
            </div>
            <div class="calendar-card display-wrap">
              <div class="left">
                <img src="./assets/images/calendar-icon.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Date</h6>
                <input type="date" id="date" placeholder="Check in - Check out"/>
                {/* <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      /> */}
              </div>
            </div>
            <div class=" guest-card display-wrap">
              <div class="left">
                <img src="./assets/images/profile.svg"/>
              </div>
              <div class="middle middle-span">
                <div>
                  <h6>Guests</h6>
                  <p class="mb-0"><span>2 Guest</span> <span>1 room</span></p>
                </div>
                <div class="right icon-search-bg">
                  <div class="search-icon-bi">
                    <i class="bi bi-search"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-package" role="tabpanel" aria-labelledby="pills-package-tab">
          <div class="tab-listing-data">
            <div class="location-card display-wrap">
              <div class="left">
                <img src="./assets/images/OBJECTS.svg"/>
              </div>
              <div class="right">
                <h6 class="padding-left">Location</h6>
                <input type="text" placeholder="Were are you going"/>
              </div>
            </div>
            <div class="calendar-card display-wrap">
              <div class="left"></div>
              <div class="right">
                <h6 class="padding-left">Sep 24 - Sep 29</h6>
                <input type="date" id="date" placeholder="Check in - Check out"/>
              </div>
            </div>
            <div class=" guest-card display-wrap">
              <div class="left">
                <img src="./assets/images/profile.svg"/>
              </div>
              <div class="middle middle-span">
                <div>
                  <h6>Guests</h6>
                  <p class="mb-0"><span>2 Guest</span> <span>1 room</span></p>
                </div>
                <div class="right icon-search-bg">
                  <div class="search-icon-bi">
                    <i class="bi bi-search"></i>
                  </div>
                </div>
              </div>

            </div>
            <div class=" guest-card display-wrap">
              <div class="left"></div>
              <div class="middle middle-span">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>)
}
export default Location;
import React from "react";
const DesireHotel = () => {
  return (
    <section class="book-desire-hotel">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12">
          <div class="content">
            <h2>Book Your
              <span class="additional-color-blue"> Desired Hotel</span>
            </h2>
            <p>Accompanying us, you have a trip full of experiences.with
              Chisfis, booking accommodation, resort villas, hotels</p>
            <button>Start your search</button>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6  col-md-6 col-sm-12">
          <div class="image-card-section">
            <div class=" ">
              <div><img src="./assets/images/card-1-1-1.png" class="images-card-bg images-cards mb-2 h-100 w-100"/></div>
              <div><img src="./assets/images/card-1-1-2.png" class="images-card-bg images-cards mt-3 h-100 w-100"/></div>
            </div>
            <div><img src="./assets/images/1-1-3.png" class="images-card-bg h-100 w-100"/></div>
          </div>

          <div>

          </div>
        </div>
      </div>
    </div>
  </section>);
};

export default DesireHotel;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../network';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: () => ({
          url: `/api/v1/category/allCatOrSubCat`
      }),
      transformResponse: (response) => response.data
    }),
  }),
});

export const { useGetAllCategoryQuery } = categoriesApi;

import React, { useState } from "react";
import { Card, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { taxAndFeesData as initialTaxAndFeesData } from "../../../data/Hotel";
import DepositDetailComponent from "./depositDetailComponent";

const TaxAndFees = () => {
  const [taxAndFeesData, setTaxAndFeesData] = useState(initialTaxAndFeesData);

  const handleCheckboxChange = (index) => {
    const updatedData = taxAndFeesData.map((item, i) => 
      i === index ? { ...item, ischecked: !item.ischecked } : item
    );
    setTaxAndFeesData(updatedData);
  };

  return (
    <Card sx={{ ml: '5px', padding: '16px' }}>
      <Typography variant="h5" component="div">
        {"Taxes and fees"}
      </Typography>
      <Typography variant="body1" component="p">
        {"What taxes do you include in your rates?"}
      </Typography>
      <FormGroup>
        {taxAndFeesData && taxAndFeesData.length > 0 && taxAndFeesData.map((item, index) => (
          <React.Fragment key={item.name}>
            <FormControlLabel
              control={
                <Checkbox
                  name={item.name}
                  checked={item.ischecked || false}
                  onChange={() => handleCheckboxChange(index)}
                />
              }
              label={item.label}
            />
            {item.ischecked && item.data && (
              <DepositDetailComponent option={item.data} />
            )}
          </React.Fragment>
        ))}
      </FormGroup>
    </Card>
  );
};

export default TaxAndFees;

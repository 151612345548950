import React, { useEffect } from "react";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import CountryFields from "./countryFields";
import HotelBasicInformation from "./hotelBasicInformation";
import { Form, useNavigate } from "react-router-dom";
import { Formik, useFormik, validateYupSchema } from "formik";
import { basicInfoSchema } from "../../../Schema/propertyRegistationSchema";
import { useSelector } from "react-redux";
import {
  usePropertyRegisterMutation,
  usePropertyTypeQuery,
} from "../../../Api/hotelApi";
import { toast } from "react-toastify";
import { authorizationCommom } from "../../../Helper/service";

const BasicInformationPage = ({ data, setActiveTab, refetch }) => {
  //   const { register, handleSubmit } = useForm();
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  console.log("data----->11", data);
  const {
    data: propertyData,
    isSuccess,
    isError,
    error,
  } = usePropertyTypeQuery();
  const [propertyRegister] = usePropertyRegisterMutation();

  useEffect(() => {
    if (error && error.data) {
      toast(error.data.message);
      authorizationCommom(error);
    }
    console.log("propertyData", propertyData);
  }, [propertyData, error]);

  const initialValues = {
    name: data?.name ? data.name : "",
    latitude: data?.latitude ? data?.latitude : "",
    longitude: data?.longitude ? data?.longitude : "",
    street_address: data?.street_address ? data.street_address : "",
    unit_number: data?.unit_number ? data.unit_number : "",
    city: data?.city ? data.city : "",
    state: data?.state ? data.state : "",
    pincode: data?.pincode ? data.pincode : "",
    property_type: data?.type_id ? data?.type_id : "",
    location: data?.location ? data?.location : "",
    country: data?.country ? data?.country : "",
    number_of_room: data?.number_of_room ? data?.number_of_room : "",
    legal_name: data?.legal_name ? data.legal_name : "",
    currency: data?.currency ? data?.currency : "",
    channel_manager: data?.channel_manager ? data?.channel_manager : "",
    part_of_chain: data?.part_of_chain ? data.part_of_chain : "",
  };

  console.log("initialValues", initialValues);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: basicInfoSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        values.property_type = Number(values.property_type);
        values.user_id = user.id;
        if(data) {
          values.propertyId = data.id;
        }
        const response = await propertyRegister(values).unwrap();
        console.log("response", response);
        if (response.status) {
          toast.success(response.message);
          alert(response.message);
          setActiveTab('property_amenties');
          refetch();
          // navigate("/property_amenties");
        }
      } catch (e) {
        console.log("error", e);
        toast.error(e.response.data.message);
      }
    },
  });

  useEffect(() => {
    if (data) {
      // Update the formik values when data is fetched
      formik.setValues({
        name: data?.name || "",
        street_address: data?.street_address || "",
        unit_number: data?.unit_number || "",
        city: data?.city || "",
        state: data?.state || "",
        pincode: data?.pincode || "",
        property_type: +data?.type_id || "",
        location: data?.location || "",
        country: data?.country || "",
        number_of_room: +data?.number_of_room || "",
        legal_name: data?.legal_name || "",
        currency: data?.currency || "",
        channel_manager: data?.channel_manager || "",
        part_of_chain: data?.part_of_chain || "",
      });
    }
  }, [data]); // Runs whenever 'data' changes

  useEffect(() => {
    console.log("formik.errors", formik.errors)
  }, [formik.errors]);

  return (
    // <div class="nk-content ">
    //   <div class="container-fluid">
    //     <div class="nk-content-inner">
    //       <div class="nk-content-body">
    <Card sx={{ ml: "5px", padding: "16px" }}>
      <React.Fragment>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                id="name"
                name="name"
                label="Property Name"
                value={formik.values.name} // Use value to bind dynamically to formik state
                onChange={formik.handleChange}
              />
            </FormControl>
            <CountryFields
              handleChange={formik.handleChange}
              formik={formik}
              errors={formik.errors}
            />
            <HotelBasicInformation
              formik={formik}
              errors={formik.errors}
              handleChange={formik.handleChange}
              propertyData={propertyData}
            />
            <br></br>
            <Button type="submit" className={"btn btn-primary"}>
              {"Submit"}
            </Button>
          </form>
        </CardContent>
      </React.Fragment>
    </Card>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BasicInformationPage;

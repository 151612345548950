import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SelectOptionComponent from "../../../Tools/SelectOptionComponent";
import {
  bathroomAmenties,
  roomAmenties,
  roomEssentialData,
  roomViewLocation,
} from "../../../data/Hotel";
import CheckBoxContainer from "../../../Tools/CheckBoxContainer";
import MultipleCheckboxComponent from "../../../Tools/MutipleCheckbox";
import RadioGroupComponent from "../../../Tools/RadioGroup";
import AmenitiesBox from "../../../Tools/AmentiesBox";
const RoomAmenties = ({
  handleChange,
  setState,
  state,
  errors,
  formik,
  initialValues,
  bathroomCount,
  setBathroomCount,
  selectAmenties,
  setSelectAmenties,
}) => {
  const [roomView, setRoomView] = useState(formik.values.roomView ? true : []);

  return (
    <Box>
      <Typography variant="h4" fontWeight={"500"}>
        {"Attract travellers with in-room amenities"}
      </Typography>
      <Typography varient="p">
        {
          "Add room amenities to give travellers a sense of what their stay will be like. Amenities will be added to all rooms, unless you deselect Add to all rooms. If you don't see what your room has, we have more amenities you can add after you’re live."
        }
      </Typography>
      <br></br>
      <Box gap={2}>
        <SelectOptionComponent
          label={"Bathroom type*"}
          labelId={"bathroom-type-label-id"}
          id={"bathroom-type-id"}
          name={"bathroom_type"}
          valueName={"value"}
          value={formik.values.bathroom_type}
          style={{ width: "20rem" }}
          valueLabel={"label"}
          errors={errors}
          handleChange={(e) => {
            handleChange(e);
            formik.handleChange(e);
          }}
          option={[
            { label: "Private Bathroom", value: "privateBathroom" },
            {
              label: "Partially open bathroom",
              value: "partiallyOpenBathroom",
            },
            {
              label: "Private Bathroom not in room",
              value: "privateBathroomNoInRoom",
            },
            { label: "Shared Bathroom", value: "sharedBathroom" },
          ]}
        />

        <Typography marginTop={2}>{"Number of bathrooms *"}</Typography>
        <Box display={"flex"} gap={2} marginTop={1}>
          <Box
            onClick={() => {
              setBathroomCount(bathroomCount > 1 ? bathroomCount - 1 : 1);
              setState((prev) => ({
                ...prev,
                bathroom_count: bathroomCount > 1 ? bathroomCount - 1 : 1,
              }));
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px", // Adjust the size
              height: "40px", // Adjust the size
              borderRadius: "50%", // Makes it a circle
              backgroundColor: "lightgray", // Background color
              cursor: "pointer",
            }}
          >
            <Typography fontWeight={"500"}>{"-"}</Typography>
          </Box>
          <Typography marginTop={1}>{bathroomCount}</Typography>
          <Box
            onClick={() => {
              setBathroomCount(bathroomCount + 1);
              setState((prev) => ({
                ...prev,
                bathroom_count: bathroomCount + 1,
              }));
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px", // Adjust the size
              height: "40px", // Adjust the size
              borderRadius: "50%", // Makes it a circle
              backgroundColor: "lightgray", // Background color
              cursor: "pointer",
            }}
          >
            <Typography>{"+"}</Typography>
          </Box>
        </Box>
        {/* {bathroomAmenties && bathroomAmenties.map((item) => {
                return (<>
                    {item.type === "select" && <Box>
                        <SelectOptionComponent
                            label={item.label}
                            labelId={item.labelId}
                            id={item.id}
                            name={item.name}
                            valueName={item.valueName}
                            style={{ width: "20rem" }}
                            valueLabel={item.valueLabel}
                            handleChange={handleChange}
                            option={item.option}
                        />
                    </Box>}
                    {item.type === "checkbox" && <Box>
                        <CheckBoxContainer
                            title={item.label}
                            handleChange={handleChange}
                        />
                    </Box>}
                    {/* {item.type === "radio" && <Box>
                       
                    </Box>}  */}
        {/* </>) */}
        {/* })} */}
        <br></br>
        <Box>
          <SelectOptionComponent
            label={"Does this room have a bath or shower?*"}
            labelId={"bath-shower-label-id"}
            id={"bath-shower-type-id"}
            name={"bath_or_shower"}
            valueName={"value"}
            value={formik.values.bath_or_shower}
            style={{ width: "20rem" }}
            errors={errors}
            valueLabel={"label"}
            handleChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
            }}
            option={[
              { label: "Bathtub Only", value: "bathtubOnly" },
              { label: "Bathtub or shower", value: "bathtubOrShower" },
              { label: "Shower only", value: "showerOnly" },
              { label: "Shower/tub combination", value: "showerTubCombo" },
            ]}
          />
        </Box>
        <br></br>
        <Box>
        <MultipleCheckboxComponent
  label={"Does this room have these essentials?"}
  gap={1}
  option={roomEssentialData}
  optionLabel={"label"}
  optionValue={"value"}
  value={Array.isArray(formik.values?.room_essential)?formik.values?.room_essential: formik.values?.room_essential?.split(",")} // Ensure correct Formik field
  optionName={"room_essential"}
  errors={formik.errors}
  onClick={(e) => {
    console.log("eeeeeeeeee",e)

    formik.handleChange(e)
    formik.setFieldValue(e.target.name, e.target.value); // Update Formik
  }}
/>

          {/* <RadioGroupComponent /> */}
          <RadioGroupComponent
            labelId={"provide-towels-label"}
            label={"Does this room provide towels?"}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            name={"provide_towels"}
            defaultValue={formik.values.provide_towels}
            optionLabel={"label"}
            optionValue={"value"}
            errors={errors}
            handleChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
            }}
          />
          <br></br>
          <RadioGroupComponent
            labelId={"room-view-label"}
            label={"Does this room have a view?"}
            defaultValue={formik.values.room_views ? "yes" : "no"}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            name={"room_view_option"}
            optionLabel={"label"}
            optionValue={"value"}
            errors={errors}
            handleChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
              setRoomView(e.target.value);
            }}
          />
          <br></br>
          {roomView === "yes" && (
            <>
              <SelectOptionComponent
                label={"Room views"}
                labelId={"room-views-label-id"}
                name={"room_views"}
                style={{ width: "15rem" }}
                value={formik.values.room_views}
                valueName={"value"}
                valueLabel={"label"}
                errors={errors}
                option={roomViewLocation}
                handleChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
              />
              <br></br>
            </>
          )}
          <br></br>
          <Box>
            <Typography
              fontWeight={500}
              sx={{ fontSize: "20px", color: "black" }}
            >
              {"Room size"}
            </Typography>
            <Typography varient="h5">{"What is the room size?"}</Typography>
            <Box display={"flex"} gap={2}>
              <TextField
                label={"Room size"}
                type="number"
                value={formik.values.room_size}
                name="room_size"
                error={
                  formik.touched.room_size && Boolean(formik.errors.room_size)
                }
                helperText={formik.touched.room_size && formik.errors.room_size}
                onChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
                varient={"outlined"}
              />
              <SelectOptionComponent
                label={"Select"}
                labelId={"room-size-label-id"}
                name={"room_size_type"}
                style={{ width: "15rem" }}
                value={formik.values.room_size_type}
                valueName={"value"}
                valueLabel={"label"}
                defaultValue={"Square metres"}
                option={[
                  { label: "Square feet", value: "Square feet" },
                  { label: "Square metres", value: "Square metres" },
                  { label: "Ping", value: "Ping" },
                ]}
                handleChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
              />
            </Box>
          </Box>
          <br></br>
          <Box>
            <Typography
              fontWeight={500}
              sx={{ fontSize: "20px", color: "black" }}
            >
              {"Room layout"}
            </Typography>
            {/* <Typography varient="h5">{"Add any amenities that set this room apart from others at your property."}</Typography> */}
            <Box display={"flex"} gap={2}>
              {/* <TextField label={"Room size"} type="number" name="room_size" onChange={handleChange} varient={"outlined"} /> */}

              <CheckBoxContainer
                name={"room_layout"}
                labelId={"sdsd"}
                value={formik.values.room_layout}
                handleChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
                errors={errors}
                optionLabel={"label"}
                optionValue={"value"}
                defaultValue={initialValues.room_layout}
                title={
                  "Add any amenities that set this room apart from others at your property."
                }
                options={[
                  {
                    label: "Single space",
                    value: "single_space",
                    description:
                      "A single, shared space for sleeping and living (like a hotel room or a studio)",
                  },
                  {
                    label: "Multi-space",
                    value: "multi_space",
                    description:
                      "A unit with more than one room (like a house, condo, or hotel suite)",
                  },
                ]}
              />
            </Box>
          </Box>
          <br></br>
          <Grid container spacing={2} gap={2}>
            {roomAmenties.map((item, index) => {
              return (
                <AmenitiesBox
                  imgWidth={30}
                  imgUrl={item.image}
                  label={item.label}
                  parentStyle={
                    selectAmenties.includes(item.value)
                      ? { background: "skyblue" }
                      : {}
                  }
                  onClick={() => {
                    if (!selectAmenties.includes(item.value)) {
                      setSelectAmenties((prev) => [...prev, item.value]);
                    } else {
                      setSelectAmenties(
                        selectAmenties.filter((_) => _ != item.value)
                      );
                    }
                  }}
                />
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomAmenties;

const AdminDashboard = () => {
  return (
    <>
      <div class="nk-content ">
        <div class="container-fluid">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <h2>In Process</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";

const CountryFields = ({ handleChange, errors, formik }) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDLiebbChfIOD9euoPvweSuco2W7nH17L4",
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;

      const getComponent = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          ?.long_name || "";

      const address = place.formatted_address;
      const city =
        getComponent("locality") || getComponent("administrative_area_level_2");
      const state = getComponent("administrative_area_level_1");
      const pincode = getComponent("postal_code");
      const latitude = place.geometry?.location?.lat() || "";
      const longitude = place.geometry?.location?.lng() || "";
      formik.setFieldValue('latitude', latitude);
      formik.setFieldValue('longitude', longitude);
      if(pincode) {
        formik.setFieldValue('pincode', pincode)
      }
      formik.setFieldValue('location', address);
      formik.setFieldValue('city', city);

      // if(pincode) {
      //   formik.setValues({
      //     pincode: pincode
      //   })
      // }
      // formik.setValues({
      //   latitude: latitude,
      //   longitude: longitude,
      //   city: city,
      //   location: address
      // });
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "in" },
    },
  });

  return (
    <>
      <FormControl sx={{ mx: "auto", mt: 2, gap: 3 }} fullWidth>
        <InputLabel id="demo-simple-select-label">{"Country"}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="country"
          // value={age}
          error={formik.touched.country && Boolean(errors.country)}
          name="country"
          label="country"
          onChange={handleChange}
          value={formik.values.country}
        >
          <MenuItem value={"india"}>{"India"}</MenuItem>
          <MenuItem value={"usa"}>{"USA"}</MenuItem>
          <MenuItem value={"australia"}>{"Australia"}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ display: "inline" }}>
        <TextField
          id="street_address"
          helperText={
            formik.touched.street_address && formik.errors.street_address
          }
          value={formik.values.street_address}
          onChange={handleChange}
          error={
            formik.touched.street_address && Boolean(errors.street_address)
          }
          name="street_address"
          label="Street Address"
          variant="standard"
          sx={{ width: "15rem" }}
        />
        <TextField
          id="unit_number"
          helperText={formik.touched.unit_number && formik.errors.unit_number}
          value={formik.values.unit_number}
          onChange={handleChange}
          error={formik.touched.unit_number && Boolean(errors.unit_number)}
          name="unit_number"
          label="Unit number (optional)"
          variant="standard"
          sx={{ ml: 5, width: "15rem" }}
        />
        <TextField
          id="city"
          value={formik.values.city}
          onChange={handleChange}
          helperText={formik.touched.city && formik.errors.city}
          error={formik.touched.city && Boolean(errors.city)}
          name="city"
          label="City"
          variant="standard"
          sx={{ width: "15rem", ml: 5 }}
        />
      </FormControl>
      <FormControl sx={{ display: "inline" }}>
        <TextField
          id="state"
          value={formik.values.state}
          helperText={formik.touched.state && formik.errors.state}
          onChange={handleChange}
          error={formik.touched.state && Boolean(errors.state)}
          name="state"
          label="State"
          variant="standard"
          sx={{ width: "15rem" }}
        />
        <TextField
          id="pincode"
          value={formik.values.pincode}
          helperText={formik.touched.pincode && formik.errors.pincode}
          onChange={handleChange}
          error={formik.touched.pincode && Boolean(errors.pincode)}
          name="pincode"
          label="Pin code"
          variant="standard"
          sx={{ ml: 5, width: "15rem" }}
        />
        <TextField
          inputRef={ref}
          defaultValue={formik.values.location}
          helperText={formik.touched.location && formik.errors.location}
          size="small"
          placeholder="Search locations..."
          error={formik.touched.location && Boolean(errors.location)}
          name="location"
          variant="standard"
          sx={{ ml: 5, width: "15rem" }}// Full width for better design
        />
        {/* <TextField
          id="location"
          value={formik.values.location}
          helperText={formik.touched.location && formik.errors.location}
          onChange={handleChange}
          error={formik.touched.location && Boolean(errors.location)}
          name="location"
          label="location"
          variant="standard"
          sx={{ ml: 5, width: "15rem" }}
        /> */}
      </FormControl>
      {/* <TextField id="state" label="state" variant="standard" /> */}
    </>
  );
};

export default CountryFields;

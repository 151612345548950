import { Box, TextField, Typography } from "@mui/material";
import RadioGroupComponent from "../../../Tools/RadioGroup";
import { useFormik } from "formik";
import { taxesSchema } from "../../../Schema/propertyRegistationSchema";

const TaxBox = ({ handleChange, formik }) => {
  return (
    <Box
      component="section"
      sx={{ p: 2, borderRadius: "12px", width: "40rem", background: "#fff" }}
    >
      <Box>
        <Typography style={{ fontWeight: "500" }} variant="p">
          {"Legal name of your property"}
        </Typography>
        <br></br>
        <Typography variant="p">
          {
            "The legal name associated with your property that you use for tax purposes or government registration. This could be the name of a company or an individual."
          }
        </Typography>
        <TextField
          type="text"
          name="legal_name"
          label="Legal name of your property"
          value={formik.values.legal_name}
          error={formik.touched.legal_name && Boolean(formik.errors.legal_name)}
          helperText={formik.touched.legal_name && formik.errors.legal_name}
          variant="outlined"
          onChange={handleChange}
        />
      </Box>
      <br></br>
      <Box>
        <Typography style={{ fontWeight: "500" }} variant="p">
          {"Is this property registered for VAT/GST?"}
        </Typography>
        <br></br>
        <Typography variant="p">
          {
            "Let us know if you collect and pay value-added tax (VAT) or goods and services tax (GST) for your property's services."
          }
        </Typography>
        <br></br>
        <RadioGroupComponent
          label={""}
          labelId={"property_register"}
          name={"property_register"}
          optionLabel={"label"}
          defaultValue={formik.values.property_register}
          handleChange={handleChange}
          errors={formik.errors}
          optionValue={"name"}
          options={[
            { label: "Yes", name: "yes" },
            { label: "No", name: "no" },
          ]}
        />
      </Box>
      <br></br>
      <Box>
        <Typography style={{ fontWeight: "500" }} variant="p">
          {"VAT/GST ID"}
        </Typography>
        <br></br>
        <Typography variant="p">
          {
            "We display this number on your invoices to identify your business for tax purposes. Please select the appropriate number according to the rules of your business or region."
          }
        </Typography>
        <br></br>
        <TextField
          type="text"
          value={formik.values.vat_gst_id}
          error={formik.touched.vat_gst_id && Boolean(formik.errors.vat_gst_id)}
          helperText={formik.touched.vat_gst_id && formik.errors.vat_gst_id}
          name="vat_gst_id"
          label="VAT/GST ID"
          variant="outlined"
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default TaxBox;

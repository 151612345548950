import { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import SelectOptionComponent from "../../../Tools/SelectOptionComponent";

const RoomPricingComponent = ({ handleChange, state, errors, formik }) => {
  console.log("wwwwwwwwwww",formik.values)
  const [handlePricePerPerson, setHandlePricePerPerson] = useState({
    occupancy: formik.values.room_occupancy ? formik.values.room_occupancy : 1,
    extraAmount: formik.values.additional_room_charge ? formik.values.additional_room_charge : 1,
    ratePerNight: formik.values.rate_per_night ? formik.values.rate_per_night : 0,
  });
  useEffect(() => {
    console.log("handlePricePerPerson-->", handlePricePerPerson);
  }, [handlePricePerPerson]);
  return (
    <Box>
      <Typography fontWeight={"500"} variant="h5" fontSize={"20px"}>
        {"Set your base rate to be competitive"}
      </Typography>
      <br></br>
      <Box
        component="section"
        sx={{
          p: 2,
          border: "1px solid white",
          borderRadius: "10px",
          background: "white",
          width: "40rem",
        }}
      >
        <Typography variant="p" sx={{ fontSize: "12px", color: "black" }}>
          {
            "Travellers will see these rates when searching for rooms. You can update this at any time. Add rates for adults now-you can add rates for kids and infants after you're live."
          }
        </Typography>
        <br></br>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            type="number"
            id="base-rate-per-night"
            label="Base rate per night (INR)"
            variant="outlined"
            name="rate_per_night"
            value={formik.values.rate_per_night}
            error={
              formik.touched.rate_per_night &&
              Boolean(formik.errors.rate_per_night)
            }
            helperText={
              formik.touched.rate_per_night &&
              formik.errors.rate_per_night
            }
            sx={{ width: "15rem" }}
            // inputProps={{ min: 250, max: 300000 }}
            onChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
              setHandlePricePerPerson((prev) => ({
                ...prev,
                ratePerNight: e.target.value,
              }));
            }}
          />
          <SelectOptionComponent
            label={"People included in base rate"}
            labelId={"room_occupancy-label-id"}
            name={"room_occupancy"}
            hideDefaultOption={true}
            defaultValue={"1"}
            value={formik.values.room_occupancy}
            errors={errors}
            style={{ width: "15rem" }}
            valueName={"value"}
            valueLabel={"label"}
            option={[
              { label: "1", value: "1" },
              { label: "2", value: "2" },
              { label: "3 (maximum occupancy)", value: "3" },
            ]}
            handleChange={(e) => {
              handleChange(e);
              formik.handleChange(e);
              setHandlePricePerPerson((prev) => ({
                ...prev,
                occupancy: e.target.value,
              }));
            }}
          />
        </Box>
        <br></br>
        {handlePricePerPerson.occupancy != 3 && (
          <>
            <Typography fontWeight={"500"} color="black" variant="p">
              {"What will you charge for each additional adult? (Optional)"}
            </Typography>
            <br></br>

            <TextField
              type="number"
              name="additional_room_charge"
              label="Amount per person"
              value={formik.values.additional_room_charge}
              min
              inputProps={{
                min: 0, // Set your minimum value
                max: 1000, // Set your maximum value
              }}
              variant="outlined"
              onChange={(e) => {
                handleChange(e);
                formik.handleChange(e);
                setHandlePricePerPerson((prev) => ({
                  ...prev,
                  extraAmount: e.target.value,
                }));
              }}
            />
            <br></br>
          </>
        )}
        <br></br>
        <Box
          component="section"
          sx={{
            p: 2,
            border: "1px solid white",
            borderRadius: "10px",
            background: "#f3f3f5",
            width: "15rem",
            // // display: "flex", // Add flex display
            // alignItems: "center", // Center vertically
            // justifyContent: "center", // Center horizontally
          }}
        >
          <Typography
            align="center"
            variant="p"
            sx={{ fontSize: "12px", color: "black" }}
          >
            {"Here's your base rate summary:"}
          </Typography>
          <ul style={{ display: "flex", gap: "5" }}>
            <li>
              <Typography
                variant="p"
                fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px" }}
              >
                {"Occupancy"}
              </Typography>
            </li>
            <li>
              <Typography
                variant="p"
                fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px", ml: "2rem" }}
              >
                {"Guests pay (INR)"}
              </Typography>
            </li>
          </ul>
          <ul style={{ display: "flex", gap: "5" }}>
            <li>
              <Typography
                variant="p"
                fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px" }}
              >
                {
                  <>
                    <PersonIcon sx={{ fontSize: "20px" }} />
                    <CloseIcon sx={{ fontSize: "15px" }} />1
                  </>
                }
              </Typography>
            </li>
            <li>
              <Typography
                variant="p"
                // fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px", ml: "3rem" }}
              >
                {`${handlePricePerPerson.ratePerNight}.00/night`}
              </Typography>
            </li>
          </ul>
          <ul style={{ display: "flex", gap: "5" }}>
            <li>
              <Typography
                variant="p"
                fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px" }}
              >
                {
                  <>
                    <PersonIcon sx={{ fontSize: "20px" }} />
                    <CloseIcon sx={{ fontSize: "15px" }} />2
                  </>
                }
              </Typography>
            </li>
            <li>
              <Typography
                variant="p"
                // fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px", ml: "3rem" }}
              >
                {`${
                  handlePricePerPerson.occupancy != 2 && handlePricePerPerson.occupancy != 3
                    ? +handlePricePerPerson.ratePerNight +
                      +handlePricePerPerson.extraAmount
                    : handlePricePerPerson.ratePerNight
                }.00/night`}
              </Typography>
            </li>
          </ul>
          <ul style={{ display: "flex", gap: "5" }}>
            <li>
              <Typography 
                variant="p"
                fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px" }}
              >
                {
                  <>
                    <PersonIcon sx={{ fontSize: "20px" }} />
                    <CloseIcon sx={{ fontSize: "15px" }} />3
                  </>
                }
              </Typography>
            </li>
            <li>
              <Typography
                variant="p"
                // fontWeight={"600"}
                sx={{ color: "#33263d", fontSize: "12px", ml: "3rem" }}
              >
                {`${
                  (handlePricePerPerson.occupancy == 1 || handlePricePerPerson.occupancy == 2)
                    ? +handlePricePerPerson.ratePerNight +
                      +handlePricePerPerson.extraAmount
                    : handlePricePerPerson.ratePerNight
                }.00/night`}
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomPricingComponent;

import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const BestOffer = ({state}) => {
  // const { data, isLoading, error } = useGetTopListProductQuery({state});
  return (
    <section class="best--offerr">
    <div class="container">
      <div class="best--offerr-header">
        <div class="header-content">
          <h3 class="common-heading">
            Best offer this <span class="additional-color-blue">month</span>
          </h3>
          <p class="common-paragraph">This can be detailed description or just a short text.</p>
        </div>
        <div class="body">
          <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 ">
              <div class="inner-card">
                <div class="left">
                  <img src="./assets/images/offer-one.png" class="offer-common"/>
                </div>
                <div class="right">
                  <h6>50% Off</h6>
                  <p>This can be detailed description or just a short text.</p>
                  <button>Book Now</button>
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6  col-md-12 col-12">
              <div class="inner-card">
                <div class="left">
                  <img src="./assets/images/offer-two.png" class="offer-common"/>
                </div>
                <div class="right">
                  <h6>50% Off</h6>
                  <p>This can be detailed description or just a short text.</p>
                  <button>Book Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
  </section>
  );
};

export default BestOffer;

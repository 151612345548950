// import { configureStore } from '@reduxjs/toolkit'
// // Or from '@reduxjs/toolkit/query/react'
// import { setupListeners } from '@reduxjs/toolkit/query'
// import { hotelApi } from './Api/hotelApi'
// import rootReducer from './rootReducer'
// // import { pokemonApi } from './services/pokemon'

// export const store = configureStore({
//     reducer: rootReducer,
//     // Adding the api middleware enables caching, invalidation, polling,
//     // and other useful features of `rtk-query`.
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware().concat([hotelApi.middleware]),
//         getDefaultMiddleware().conect([locationApi.middleware]),
//         getDefaultMiddleware().conect([categoriesApi.middleware]),
//         getDefaultMiddleware().conect([categoriesApi.middleware]),
//         [productsApi.reducerPath]:productsApi.reducer,
// })

// // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)


import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './process/main/auth/authApi';
import authReducer from './process/main/auth/authSlice';
import { locationApi } from './process/main/location/locationApi';
// import { categoriesApi } from './process/categories/categoriesApi';
import { categoriesApi } from './process/main/categories/categoriesApi';
// import { productsApi } from '../../process/products/productApi';
import { hotelApi } from './Api/hotelApi';
import { productsApi } from './process/main/products/productApi';
import { usersApi } from './process/admin/users/usersApi';
import { agentsApi } from './process/admin/agent/agentApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [locationApi.reducerPath]:locationApi.reducer,
    [categoriesApi.reducerPath]:categoriesApi.reducer,
    [productsApi.reducerPath]:productsApi.reducer,
    [hotelApi.reducerPath]: hotelApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [agentsApi.reducerPath]: agentsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware,locationApi.middleware,categoriesApi.middleware,productsApi.middleware, hotelApi.middleware, usersApi.middleware, agentsApi.middleware),
});
import React from "react";
import { useGetAllPopularLocationQuery } from "../../../process/main/location/locationApi";
import CopyRight from "./CopyRight";
import Main from "./Main";

const Footer = () => {
  const { data, isLoading, error } = useGetAllPopularLocationQuery();
  return (
    <>
    <Main/>
    <CopyRight/>
    </>
  );
};

export default Footer;

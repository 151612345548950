// import PartnerHeader from "../Layout/header";
import PartnerHeader from "../Layout/Partner/header";
import PartnerSidebar from "../Layout/Partner/sidebar";
import Home from "../Pages/Main/Home";
import Signup from "../Pages/Main/Signup";
import SignInUser from "../Pages/Main/SignInUser";
import LoginPage from "../Pages/Partner/Login";
import RatesAndAvailablity from "../Pages/Partner/RatesAndAvailablity";
import RegisterProperty from "../Pages/Partner/RegisterProperty";
import ReservationPage from "../Pages/Partner/Reservation";
import RegisterPage from "../Pages/Partner/Signup";
import SignIn from "../Pages/Partner/Signup";
import RegistrationHotel from "../RegistrationHotel/Component";
import BasicInformationPage from "../RegistrationHotel/Component/BasicInformation";
import PhotosPage from "../RegistrationHotel/Component/Photos";
import PointOfInterest from "../RegistrationHotel/Component/PointOfInterest";
import PolicyAndSetting from "../RegistrationHotel/Component/PolicyAndSetting";
import PropertyAmenties from "../RegistrationHotel/Component/PropertyAmenties";
import PropertyContract from "../RegistrationHotel/Component/PropertyContract";
import RoomAndRates from "../RegistrationHotel/Component/RoomAndRates";
import PropertyTaxes from "../RegistrationHotel/Component/Taxes";
import withAuthorization from "../WithAuthorization";
import AdminDashboard from "../Pages/Admin/Dashboard";
import Users from "../Pages/Admin/Users";
import AgentList from "../Pages/Admin/Agent";
const HomePage = withAuthorization(Home, "user",false,true);
const AdminDashboardd = withAuthorization(AdminDashboard, "admin",true,true,"Dashboard");
const AdminUsersList = withAuthorization(Users,"admin",true,true,"Users");
const AdminAgentList = withAuthorization(AgentList,"admin",true,true,"Users");

// const SignUpPage = withAuthorization(Signup, "",false,true);
export const partnerAuthRoute = [
  {
    path: "/sign-up",
    component: <RegisterPage />,
  },
  {
    path: "login",
    component: <LoginPage />,
  },
];

export const partnerRoute = [
  {
    path: "*",
    component: <PropertyContract />,
  },
  {
    label: "Property Details",
    path: "property_details",
    component: <RegisterProperty />,
  },
  {
    label: "Rates And Availability",
    path: "rates",
    component: <RatesAndAvailablity />,
  },
  {
    label: "Reservation",
    path: "reservation",
    component: <ReservationPage />,
  },
  {
    path: "policy_and_setting",
    component: <PolicyAndSetting />,
  },
  {
    path: "property_amenties",
    component: <PropertyAmenties />,
  },
  {
    path: "room_and_rates",
    component: <RoomAndRates />,
  },
  {
    path: "property_taxes",
    component: <PropertyTaxes />
  },
  {
    path: "room_upload_photos",
    component: <PhotosPage />,
  },
  {
    path: "point_of_interest",
    component: <PointOfInterest />,
  },
];

export const adminRoute=[
  {
    path: "dashboard",
    component: <AdminDashboardd />
  },
  {
    path: "userlist",
    component: <AdminUsersList />
  },
  {
    path: "agentlist",
    component: <AdminAgentList />
  }
];

export const mainRoute=[
  {
    label: "*",
    path: "/",
    auth:false,
    component: <HomePage/>,
  },
  {
    label: "*",
    path: "/signup",
    auth:false,
    component: <Signup/>,
  },
  {
    label: "*",
    path: "/signin",
    auth:false,
    component: <SignInUser/>,
  }
]
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ auth, component }) => {
    console.log("auth token", auth);

    console.log("auth", auth===null);
  if (auth === null) {
    return <Navigate to="/partner/login" replace />;
  }
  // If authenticated, render the given component
  return component;
};

export default PrivateRoute;

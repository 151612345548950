import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MultipleImageBox from "../../../Tools/MutipleImageBox";
import {
  useGetRoomByPropertyQuery,
  useSendOtpMutation,
  useUploadPhotoMutation,
} from "../../../Api/hotelApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PhotosPage = ({ propertyData, refetch, setIsActive }) => {
  const [exteriorImage, setExteriorImage] = useState([]);
  const [interiorImage, setInteriorImage] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const { data, error, isLoading } = useGetRoomByPropertyQuery(propertyData?.id);
  const navigate = useNavigate();
  const [images, setImages] = useState({});
  const [
    uploadPhoto,
    {
      data: addedData,
      isError: phtotoError,
      isLoading: photoLoading,
    },
  ] = useUploadPhotoMutation();
  



// const user = { id: 1 }
 
  // const [
  //   sendOtp,
  //   {
  //     data: selectedRoomData,
  //     isError: selectedRoomError,
  //     isLoading: selectedRoomLoading,
  //   },
  // ] = useSendOtpMutation();

  //  useEffect(() => {
  //    console.log("datat---->", selectedRoomData);
  //  }, [images, selectedRoomData]);
  
  // const getSelectedRoom = async () => {
  //   await sendOtp({ id: 1 });
  // };

  // useEffect(() => {
  //   getSelectedRoom();
  // }, []);

  const savePhotos=async()=>{
    try{

      if(exteriorImage.length>0 && interiorImage.length>0 && Object.keys(images).length>0){
      let formData = new FormData();
      formData.append("uid",user.id);
      for (let index = 0; index < exteriorImage.length; index++) {
        formData.append("exteriorImage",exteriorImage[index].file);
      }
      for (let index = 0; index < interiorImage.length; index++) {
        formData.append("interiorImage",interiorImage[index].file);
      }
      for (let index = 0; index < data.length; index++) {
          for (let index2 = 0; index2 < images[`Room_${data[index].id}`].length; index2++) {
            formData.append(`Room_${data[index].id}`,images[`Room_${data[index].id}`][index2].file);
          }
      }
      const save = await uploadPhoto(formData).unwrap();
      alert("Image Upload Successfully");
      toast.success("Image Upload Successfully");
      setIsActive('property_taxes');
      // navigate('/property_taxes');
    }else{
      alert("Please select images");
    }
    }catch(e){
      alert(e.message);
    }
  }

  // useEffect(() => {
  //   console.log("selectedRoomData", selectedRoomData);
  //   console.log("selectedRoomError", selectedRoomError);
  //   console.log("selectedRoomLoading", selectedRoomLoading);
  // }, [selectedRoomLoading, selectedRoomError, selectedRoomData]);
  // const { data: selectedRoomData, error: selectedRoomError, loading: selectedRoomLoading } = useSendOtpMutation();

  const handleSetImages = (roomName, newImages) => {
    setImages((prevState) => ({
      ...prevState,
      [roomName]: newImages, // Update images for the specific room
    }));
    console.log("newImages==========>", newImages);
  };

  return (
    <div class="nk-content ">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <Card sx={{ ml: "5px", padding: "16px" }}>
              <React.Fragment>
                <CardContent>
                  <Typography sx={{ fontWeight: "500" }}>
                    {"Recommended photos"}
                  </Typography>
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid white",
                      borderRadius: "10px",
                      background: "#f3f3f5",
                      width: "30rem",
                    }}
                  >
                    <MultipleImageBox
                      label={"Exterior"}
                      setImages={setExteriorImage}
                      images={exteriorImage}
                      name={"exterior_image"}
                      id={"exterior-image-label"}
                    />
                    <hr></hr>
                    <MultipleImageBox
                      label={"Interior"}
                      setImages={setInteriorImage}
                      images={interiorImage}
                      name={"interior_image"}
                      id={"interior-image-label"}
                    />
                  </Box>
                  <Typography sx={{ fontWeight: "500" }}>
                    {"Room photos"}
                  </Typography>
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid white",
                      borderRadius: "10px",
                        background: "#f3f3f5",
                      width: "30rem",
                    }}
                  >
                    {console.log("datadata", data)}
                    {data &&
                      data.length > 0 &&
                      data.map((item, index) => {
                        const roomKey = `Room_${item.id}`;
                        return (
                          <MultipleImageBox
                            key={roomKey}
                            label={roomKey}
                            setImages={(newImages) => {
                              handleSetImages(roomKey, newImages);
                            }}
                            common={true}
                            images={images[roomKey] || []} // Use existing images or an empty array
                            name={roomKey}
                            id={`${roomKey}_label`}
                            defaultImages={item.images?.split(",")}
                          />
                        );
                      })}
                  </Box>
                </CardContent>
              </React.Fragment>
              <Button type="button" onClick={()=>savePhotos()} className={"btn btn-primary"}>
                {"Save"}
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotosPage;

import React, { useEffect } from "react";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";
import { useFormik } from "formik";
import { registrationSchema } from "../../../Schema/propertyRegistationSchema";
import { useLoginMutation, useSignUpMutation } from "../../../Api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role_id: 2
  };

  const [
    signUp,
    {
      data: registerData,
      isSuccess,
      error: registerErrors,
      isError: registerError,
      isLoading: registerLoading,
    },
  ] = useSignUpMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      // toast.success(registerData.data.message);
      localStorage.setItem('user', JSON.stringify(registerData.data.user));
      localStorage.setItem('token', registerData.data.token);
      navigate('/basic');
      toast.success(registerData.data.message);
    } else if (registerErrors && registerErrors.data) {
      alert(registerErrors?.data.message);
      toast.error(registerErrors?.data.message);
    }
  }, [isSuccess, registerError]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { resetForm }) => {
      console.debug("Form Data: ", values);
      signUp(values);
      resetForm();
    },
  });

  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* Left Section with Image */}
     

      {/* Right Section with Form */}
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          bgcolor: "#f7f8fc",
        }}
      >
        {/* Logo */}
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333" }}>
        <img src="/getMyHotelLogo.png" />
        </Typography>

        {/* Form Container */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            mt: 4,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            padding: 3,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                mb: 1,
                textAlign: "center",
                color: "#333",
              }}
            >
              Register
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "#777", mb: 3 }}
            >
              Please fill in your details to create an account
            </Typography>

            {/* Input Fields */}
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <TextField
                label="First Name"
                fullWidth
                variant="outlined"
                name="firstname"
                size="small"
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                helperText={formik.touched.firstname && formik.errors.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
              />
              <TextField
                label="Last Name"
                fullWidth
                variant="outlined"
                name="lastname"
                size="small"
                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                helperText={formik.touched.lastname && formik.errors.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
              />
            </Box>
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              size="small"
              type="email"
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone Number"
              fullWidth
              variant="outlined"
              size="small"
              type="tel"
              name="phone"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Set Password"
              fullWidth
              variant="outlined"
              size="small"
              type="password"
              name="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Confirm Password"
              fullWidth
              variant="outlined"
              size="small"
              type="password"
              name="confirmPassword"
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              sx={{ mb: 3 }}
            />

            {/* Register Button */}
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#7b2ff7",
                color: "white",
                fontWeight: "bold",
                ":hover": { backgroundColor: "#631cd3" },
              }}
            >
              Register
            </Button>
          </form>
        </Box>
        {/* Footer */}
        <Typography
          variant="caption"
          sx={{ mt: 4, color: "#aaa", textAlign: "center" }}
        >
          Do you have account ? <Typography variant="p" sx={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => navigate('/login')}>{"Login"}</Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RegisterPage;

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";

const DepositDetailComponent = ({ option }) => {
  const [requireAndDeposit, setRequireAndDeposit] = useState(option);

  const setValue = (item3, pindex, cindex) => {
    console.log(" ", item3);
    console.log("cindex", cindex);

    if (item3.data) {
      if (requireAndDeposit[pindex]?.data[cindex]?.type === "radio") {
        setRequireAndDeposit(
          requireAndDeposit.map((item, i) =>
            i === pindex
              ? {
                  ...item,
                  data: item.data.map((dataItem, j) =>
                    j === cindex
                      ? { ...dataItem, ischecked: !dataItem.ischecked }
                      : dataItem
                  ),
                }
              : item
          )
        );
      } else {
        setRequireAndDeposit(
          requireAndDeposit.map((item, i) =>
            i === pindex
              ? {
                  ...item,
                  data: item.data.map((dataItem, j) =>
                    j === cindex
                      ? { ...dataItem, ischecked: !dataItem.ischecked }
                      : { ...dataItem, ischecked: false }
                  ),
                }
              : item
          )
        );
      }
    } else {
      console.log(
        "requireAndDeposit[pindex]1",
        requireAndDeposit[pindex]?.data[cindex]
      );
      setRequireAndDeposit(
        requireAndDeposit.map((item, i) =>
          i === pindex
            ? {
                ...item,
                data: item.data.map((dataItem, j) =>
                  j === item3.mainIndex
                    ? { ...dataItem, ischecked: false }
                    : dataItem
                ),
              }
            : item
        )
      );
    }
  };

  useEffect(() => {
    console.log("requireAndDeposit-->", requireAndDeposit);
  }, [requireAndDeposit]);

  return (
    <div style={{ marginLeft: "10px" }}>
      {requireAndDeposit &&
        requireAndDeposit.length > 0 &&
        requireAndDeposit.map((item2, index2) => (
          <React.Fragment key={index2}>
            <label>{item2.label}</label>
            <FormControl component="fieldset">
              {console.log("item2", item2)}
              {!item2.type && item2.data && item2.data.length > 0 && (
                <RadioGroup name={item2.name}>
                  {console.log("item2", item2)}
                  {item2.data.map((item3, index3) => (
                    <React.Fragment key={index3}>
                      {item3.type === "radio" ? (
                        <FormControlLabel
                          name={item3.name}
                          value={item3.label}
                          className="ss111"
                          onClick={() =>
                            !item3.ischecked && setValue(item3, index2, index3)
                          }
                          control={
                            <Radio name={item3.name} value={item3.label} />
                          }
                          label={item3.label}
                        />
                      ) : item3.type === "select" ? (
                        <>
                          {console.log("item2.default", item2.default)}
                          <Select
                            name={item3.name}
                            defaultValue={item2.default}
                            label={item3.label}
                            onChange={(e) =>
                              console.log("e.target.valye", e.target.value)
                            }
                            placeholder="sd"
                            fullWidth
                          >
                            <MenuItem value={""}>{"--select--"}</MenuItem>
                            {item3.data.map((option, idx) => (
                              <>
                                {console.log("option--->", option)}
                                <MenuItem key={idx} value={option.label}>
                                  {option.label}
                                </MenuItem>
                                {item3.typeOption == "option_inside_input" &&
                                  option.ischecked &&
                                  option?.data?.map((selectChild, xx) => (
                                    <>
                                      {console.log("selectChild", selectChild)}
                                      {(selectChild.type == "text" ||
                                        selectChild.type == "number") && (
                                        <TextField
                                          fullWidth
                                          type={selectChild.type}
                                          name={selectChild.name}
                                          className="sss222"
                                          label={item3.label || "Enter text"}
                                          variant="outlined"
                                        />
                                      )}
                                      {selectChild.type == "select" && (
                                        <Select
                                          name={selectChild.name}
                                          defaultValue={selectChild.default}
                                          label={selectChild.label}
                                          placeholder="sd"
                                          fullWidth
                                        >
                                          {selectChild.data &&
                                            selectChild?.data?.map(
                                              (selectChildItem, xxx) => (
                                                <MenuItem
                                                  key={xxx}
                                                  value={selectChildItem.label}
                                                >
                                                  {selectChildItem.label}
                                                </MenuItem>
                                              )
                                            )}
                                        </Select>
                                      )}
                                    </>
                                  ))}
                              </>
                            ))}
                          </Select>
                        </>
                      ) : item3.type === "text" ||
                        item3.type === "number" ||
                        item3.type === "date" ? (
                        <TextField
                          fullWidth
                          type={item3.type}
                          name={item3.name}
                          className="sss222"
                          label={item3.label || "Enter text"}
                          variant="outlined"
                        />
                      ) : item3.type === "checkbox" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={item3.name}
                              className="sd"
                              checked={item3.ischecked || false}
                              onChange={() => setValue(item3, index2, index3)}
                            />
                          }
                          label={item3.label || "Check me"}
                        />
                      ) : null}

                      {/* Render nested data for date range or age select */}
                      {item3.data &&
                        item3.data.length > 0 &&
                        item3.ischecked && (
                          <Box display="flex" flexDirection="column" ml={2}>
                            {item3.data.map((nestedItem, nestedIndex) => (
                              <React.Fragment key={nestedIndex}>
                                {nestedItem.type === "date" ? (
                                  <TextField
                                    type="date"
                                    label={nestedItem.label}
                                    name={nestedItem.name}
                                    placeholder={nestedItem.placeholder}
                                    fullWidth
                                  />
                                ) : nestedItem.type === "select" ? (
                                  <Select
                                    label={nestedItem.label}
                                    name={nestedItem.name}
                                    defaultValue=""
                                    placeholder="sd"
                                  >
                                    <MenuItem value="">{"--select--"}</MenuItem>
                                    {nestedItem.data.map((age, ageIdx) => (
                                      <MenuItem key={ageIdx} value={age}>
                                        {age}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                ) : null}
                              </React.Fragment>
                            ))}
                          </Box>
                        )}
                    </React.Fragment>
                  ))}
                </RadioGroup>
              )}

              {/* Render additional types for item2 */}
              {item2.type === "number" && (
                <TextField
                  label={item2.label}
                  type="number"
                  name={item2.name}
                  fullWidth
                />
              )}

              {item2.type === "select" && (
                <>
                  <Select
                    name={item2.name}
                    defaultValue={item2.default}
                    onChange={(e) => {
                      let valueee = e.target.value;
                      console.log("valueee->>>", valueee);

                      setValue(
                        item2,
                        index2,
                        item2.data.findIndex((e) => e.name == valueee)
                      );
                      console.log("item211", item2);
                    }}
                    fullWidth
                  >
                    <MenuItem>{"--select--"}</MenuItem>
                    {item2.data.map((opt, idx) => (
                      <MenuItem key={idx} value={opt.name}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {console.log("item2item2", item2)}
                  {item2.data && item2.data.length > 0 && item2.data.map((item22, idx) => {
                    { 
                      return(item2.typeOption === "option_inside_input" &&
                        item22.ischecked &&
                        item22.data.map((selectChild, xx) => (
                          <>
                            {selectChild.type === "text" || selectChild.type === "number" &&
      
                            (<TextField 
                              type={selectChild.type} 
                              name={selectChild.name}
                               label={selectChild.label} 
                               fullWidth
                               />
                              )

                            }

                            {selectChild.type == "select" && (
                              <Select
                                name={selectChild.name}
                                defaultValue={selectChild.default}
                                label={selectChild.label}
                                placeholder="sd"
                                fullWidth
                              >
                                {selectChild.data &&
                                  selectChild?.data?.map(
                                    (selectChildItem, xxx) => (
                                      <MenuItem
                                        key={xxx}
                                        value={selectChildItem.label}
                                      >
                                        {console.log(
                                          "selectChildItem",
                                          selectChildItem
                                        )}
                                        {selectChildItem.label}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            )}
                          </>
                        )));
                    }
                  })}
                </>
              )}

              {item2.type === "checkbox" && (
                <FormControlLabel
                  control={<Checkbox name={item2.name} />}
                  label={item2.label}
                />
              )}
            </FormControl>
          </React.Fragment>
        ))}
    </div>
  );
};

export default DepositDetailComponent;

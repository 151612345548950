import React from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const CleaningFeeComponent = () => {
  const [active, setActive] = React.useState({
    cleaning: "no",
    cleaningFee: false,
    cleaningFeeCurr: "amount",
  });
  const style = {
    cleaning: {
      background: "blue",
      color: "#fff",
    },
    cleaningCheckbox: {
      display: "grid",
    },
  };

  const handleCleaning = (depositStatus) => {
    setActive((prev) => ({ ...prev, cleaning: depositStatus }));
  };

  return (
    <Card sx={{ ml: '5px', padding: '16px' }}>  
      <Typography level="h3" sx={{ fontWeight: "600" }}>
        {"Do you charge a cleaning fee?"}
      </Typography>
      <Button
        style={active.cleaning === "yes" ? style.cleaning : {}}
        onClick={() => handleCleaning("yes")}
      >
        {"Yes"}
      </Button>
      <Button
        style={active.cleaning === "no" ? style.cleaning : {}}
        onClick={() => handleCleaning("no")}
      >
        {"No"}
      </Button>
      {active.cleaning === "yes" && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Cleaning fee"
            onClick={(e) =>
              setActive((prev) => ({
                ...prev,
                cleaningFee: e.target.checked,
              }))
            }
          />
        </FormGroup>
      )}
      {active.cleaningFee && (
        <Box
          display="flex"
          alignItems="center"
          border={1}
          borderColor="grey.300"
          borderRadius={2}
          padding={2}
          bgcolor="background.paper"
          ml={2} // Adds a small left margin
        >
          <RadioGroup
            row
            value={active.cleaningFeeCurr}
            onChange={(e) =>
              setActive((prev) => ({
                ...prev,
                cleaningFeeCurr: e.target.value,
              }))
            }
          >
            <FormControlLabel
              value="amount"
              control={<Radio />}
              label="Amount"
            />
            <FormControlLabel
              value="percent"
              control={<Radio />}
              label="Percent"
            />
          </RadioGroup>
        </Box>
      )}
      {active.cleaningFee&&active.cleaningFee && (
        <div>
          <TextField
            id="outlined-multiline-flexible"
            label={active.cleaningFeeCurr === "amount" ? "Amount (INR)" : "Percent (%)"}
            multiline
            name={active.cleaningFeeCurr}
            type="text"
          />
          <Select defaultValue={"Per person"}>
            <MenuItem value="Per person">{"Per person"}</MenuItem>
            <MenuItem value="Per accommodation">{"Per accommodation"}</MenuItem>
          </Select>
          <Select defaultValue={"Per night"}>
            <MenuItem value="Per night">{"Per night"}</MenuItem>
            <MenuItem value="Per stay">{"Per stay"}</MenuItem>
            <MenuItem value="Per week">{"Per week"}</MenuItem>
          </Select>
        </div>
      )}
    </Card>
  );
};

export default CleaningFeeComponent;

import "./App.css";
import "../src/public/css/dashlite.css";
import "../src/public/css/dashboard.css";
import Routing from "./Routes";
import { store } from "./store";
import { setToken } from "./Slice/authSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { setToken } from "./Slice/authSlice";

function App() {
  const localToken = localStorage.getItem("token");

  if(localToken){
    store.dispatch(setToken(localToken))
  }
  return <Routing tokenn={localToken} />;
}

export default App;

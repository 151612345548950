import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

const RadioGroupComponent = ({ label, defaultValue, labelId, name, options, handleChange, optionLabel, optionValue, errors }) => {
    return (
        <FormControl>
            <FormLabel id={labelId}>{label}</FormLabel>
            <RadioGroup
                aria-labelledby={labelId}
                defaultValue={defaultValue}
                name={name}
                onChange={handleChange}
            >
                {options && options.length > 0 && options.map((item) => <FormControlLabel value={item[optionValue]} control={<Radio />} label={item[optionLabel]} />)}
            </RadioGroup>
            {(errors && errors[name]) && <Typography sx={{color: "red"}}>{errors[name]}</Typography>}
        </FormControl>
    )
}

export default RadioGroupComponent;
// import { Height } from "@mui/icons-material";
import { useFormik } from "formik";
import CommonTextArea from "../../../Tools/TextArea";
import { pointOfInterestSchema } from "../../../Schema/propertyRegistationSchema";
import { useGetPropertyDataQuery, useUpdatePointOfInterestMutation } from "../../../Api/hotelApi";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useEffect } from "react";
// import { height } from "@mui/system";

const PointOfInterest = () => {
  const initialValues = {
    point_of_interest: ""
  }

  const [updatePointOfInterest] = useUpdatePointOfInterestMutation();
  const user = JSON.parse(localStorage.getItem('user'));
  const { data, error, isLoading } = useGetPropertyDataQuery(user?.id);


  const formik = useFormik({
    initialValues,
    validationSchema: pointOfInterestSchema,
    onSubmit: async (values, { resetForm }) => {
      values.property_id = data?.id
      try {
        const response = await updatePointOfInterest(values).unwrap();
        if (response.status) {
          toast.success(response.message);
          alert(response.message);
        }
      } catch (e) {
        toast.error(e.response.data.message);
        alert(e.response.data.message);
        console.log("Error", e);
      }
      // console.log("valuess--->", values);
    },
  });
  useEffect(() => {
     if(data) {
      formik.setValues({
        point_of_interest: data.point_of_interest
      })
     }
  }, [data]);
  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
             <form onSubmit={formik.handleSubmit}>
             <CommonTextArea
                mainHeading={"Points of interest"}
                childHeading={"In-Room Comfort & Features"}
                boxWidth={"40rem"}
                style={{width: "35rem", height: "10rem"}}
                error={formik.errors}
                value={formik.values.point_of_interest}
                onChange={formik.handleChange}
                name={"point_of_interest"}
             />
             <Button type="submit" className="btn btn-primary">{"Save"}</Button>
             </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointOfInterest;

import React from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const MemoriableSection = ({state}) => {
  return (
    <section class="memoriable-section">
    <div class="container">
      <div class="header-content text-center">
        <h3 class="common-heading">The Most <span class="additional-color-blue">Memorable</span> Rest Time Status Here
        </h3>
        <p class="common-paragraph">Our target is to provide with a best quality services.</p>
      </div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-delhi-tab" data-bs-toggle="pill" data-bs-target="#pills-delhi"
            type="button" role="tab" aria-controls="pills-delhi" aria-selected="true">Delhi</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link pills-tab-al" id="pills-mumbai-tab" data-bs-toggle="pill"
            data-bs-target="#pills-mumbai" type="button" role="tab" aria-controls="pills-mumbai"
            aria-selected="false">Mumbai</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link pills-tab-al" id="pills-bengaluru-tab" data-bs-toggle="pill"
            data-bs-target="#pills-bengaluru" type="button" role="tab" aria-controls="pills-bengaluru"
            aria-selected="false">Bengaluru</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link pills-tab-al" id="pills-hyderabad-tab" data-bs-toggle="pill"
            data-bs-target="#pills-hyderabad" type="button" role="tab" aria-controls="pills-hyderabad"
            aria-selected="false">Hyderabad</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link pills-tab-al" id="pills-dubai-tab" data-bs-toggle="pill" data-bs-target="#pills-dubai"
            type="button" role="tab" aria-controls="pills-dubai" aria-selected="false">Dubai</button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-delhi" role="tabpanel" aria-labelledby="pills-delhi-tab">
          <div class="row  memoriable-cards-mainwrap">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png" alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>     ₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>

              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-two.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-three.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-four.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div class="tab-pane fade" id="pills-mumbai" role="tabpanel" aria-labelledby="pills-mumbai-tab">
          <div class="row memoriable-cards-mainwrap">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png" alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>

              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png" alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-bengaluru" role="tabpanel" aria-labelledby="pills-bengaluru-tab">
          <div class="row memoriable-cards-mainwrap">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>

              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6  memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-hyderabad" role="tabpanel" aria-labelledby="pills-hyderabad-tab">
          <div class="row memoriable-cards-mainwrap">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>

              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-dubai" role="tabpanel" aria-labelledby="pills-dubai-tab">
          <div class="row memoriable-cards-mainwrap">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>

              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 memoriable-cards-main">
              <div class="header-image">
                <img src="./assets/images/memorible-one.png"alt="" class="memorable-img"/>
                <img class="sticky-image" src="./assets/images/like.svg"/>
              </div>
              <div class="body-content">
                <div class="rating-wrap">
                  <span class="rating-count">5.5</span>
                  <img src="./assets/images/rating-star.svg"/>
                  <span class="reviews-content">(23 reviews)</span>
                </div>
                <div class="names-wrap">
                  <h5>Samode Palace</h5> <span>Chomu</span>
                </div>
                <div class="pricing-content">
                  <h5>₹ 30,000 <span>per night</span></h5>
                  <p>₹ 35,000</p>
                </div>
                <div class="taxes-wrap">
                  <p>₹74,766 total includes taxes & fees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </section>
  );
};

export default MemoriableSection;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_URL } from '../../network';

export const agentsApi = createApi({
  reducerPath: 'agentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_API_URL,
  }),
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: () => ({
          url: `agentListApi`
      }),
      transformResponse: (response) => response.data
    }),
  }),
});

export const { useGetAgentsQuery } = agentsApi;

import React, { useEffect, useState } from "react";
import imageSrc from "../../../public/air_condition.png";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from "@mui/material";
import { hotelAmenties, propertyAmenties } from "../../../data/Hotel";
import AmenitiesBox from "../../../Tools/AmentiesBox";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  basicInfoSchema,
  propertyUpdateAmentiesSchema,
} from "../../../Schema/propertyRegistationSchema";
import { useGetPropertyDataQuery, usePropertyAmentiesPropertySaveMutation } from "../../../Api/hotelApi";

const PropertyAmenities = ({ error, data, isLoading, setIsActive, refetch }) => {
  const [selectAmenties, setSelectAmenties] = useState([]);
  // const user = JSON.parse(localStorage.getItem('user'));
  // const { data, error, isLoading } = useGetPropertyDataQuery(user?.id);
  const [propertyAmentiesPropertySave] = usePropertyAmentiesPropertySaveMutation();

  const handleInputChange = (e) => {
    console.log(`Changed ${e.target.name} to ${e.target.value}`);
  };
  // const navigate = useNavigate();
  const initialValues = {
    check_in: "",
    check_out: "",
    lateCheckAvailable: "",
    minimumAge: "",
    pool_access: "",
    accessibility_feature: "",
    outdoor_recreation_areas: "",
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: propertyUpdateAmentiesSchema,
    onSubmit: async (values, { resetForm }) => {

      values.outdoor_space = values.outdoor_recreation_areas;
      values.parking = selectAmenties.includes('parking') ? "yes" : "no";
      values.gym = selectAmenties.includes('gym') ? "yes" : "no";
      values.hot_tub = selectAmenties.includes('hot_tub') ? "yes" : "no";
      values.restaurent = selectAmenties.includes('restaurent') ? "yes" : "no";
      values.pet_friendly = selectAmenties.includes('pet_friendly') ? "yes" : "no";
      values.air_condition = selectAmenties.includes('air_condition') ? "yes" : "no";
      values.water_park = selectAmenties.includes('water_park') ? "yes" : "no";
      values.spa = selectAmenties.includes('spa') ? "yes" : "no";
      values.internet = selectAmenties.includes('internet') ? 'yes' : 'no';
      values.kitchen = selectAmenties.includes('kitchen') ? 'yes' : 'no';
      values.minimumAge = +values.minimumAge;
      values.check_in = values.check_in;
      values.check_out = values.check_out;
      values.lateCheckAvailable = values.lateCheckAvailable;
      values.property_id = Number(data.id);
      try {
        values.property_id = +data.id;
        const response = await propertyAmentiesPropertySave(values).unwrap();
        console.log("response", response)
        if(response.status) {
          toast.success(response.message);
          setIsActive('room_and_rates');
          alert(response.message);
          refetch();
        }
      } catch (e) {
        toast.error(e.response.data.message);
        alert(e.response.data.message);
        console.log("error", e);
      }
    },
  });

  
  useEffect(() => {
    if(data) {
      console.log("check_in", data)
       formik.setValues({
        check_in: data.check_in || '',
        check_out: data.check_out || '',
        lateCheckAvailable: data.lateCheckAvailable || '',
        minimumAge: data.minimumAge || '',
        pool_access: data.amenities_data?.pool_access || '',
        accessibility_feature: data?.amenities_data?.accessibility_feature || '',
        outdoor_recreation_areas: data?.amenities_data.outdoor_recreation_areas || ''
       });
       if(data.amenities_data) {
            if (data && data.amenities_data) {
              Object.entries(data.amenities_data).forEach(([key, value]) => {
                if (value === "yes") {
                  setSelectAmenties((prev) => [...prev, key]);
                }
              });
            }    
       }
    }
  }, [data]);

  useEffect(() => {
    console.log("selectAmenties", selectAmenties)
  }, [selectAmenties])
  return (
    <Box p={3}>
      <form onSubmit={formik.handleSubmit}>
        {propertyAmenties.map((section, sectionIndex) => (
          <Box key={sectionIndex} mb={3}>
            <Typography variant="h6" mb={2}>
              {section.label}
            </Typography>
            {section.data &&
              section.data.length > 0 &&
              section.data.map((field, fieldIndex) => (
                <Box key={fieldIndex} mb={2}>
                  {field.type === "select" && (
                    <FormControl fullWidth>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        name={field.name}
                        value={formik.values[field.name]}
                        error={
                          formik.errors[field.name]
                        }
                        onChange={(e) => {
                          handleInputChange(e);
                          formik.handleChange(e);
                        }}
                      >
                        {field.data.map((option, optionIndex) => (
                          <MenuItem
                            key={optionIndex}
                            value={option.value}
                            selected={option.selected}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {/* Render RADIO Input */}
                  {field.type === "radio" && (
                    console.log("field.name=============>",field.name),
                    <>
                      <RadioGroup
                        name={field.name}
                        value={formik.values[field.name]}
                        // onError={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                        onChange={(e) => {
                          handleInputChange(e);
                          formik.handleChange(e);
                        }}
                        row
                      >
                        {field.data.map((field2, index) => (
                          <FormControlLabel
                            key={index}
                            value={field2.value}
                            control={<Radio/>}
                            label={field2.label}
                          />
                        ))}
                      </RadioGroup>
                      {/* Display error message if there's an error and the field is touched */}
                      {formik.touched[field.name] &&
                        formik.errors[field.name] && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {formik.errors[field.name]}
                          </div>
                        )}
                    </>
                  )}
                </Box>
              ))}
          </Box>
        ))}
        <Grid container spacing={2} gap={2}>
          {hotelAmenties.map((item, index) => {
            return (
              <AmenitiesBox
                imgWidth={30}
                imgUrl={item.image}
                label={item.label}
                parentStyle={
                  selectAmenties.includes(item.name)
                    ? { background: "skyblue" }
                    : {}
                }
                onClick={() => {
                  if (!selectAmenties.includes(item.name)) {
                    setSelectAmenties((prev) => [...prev, item.name]);
                  } else {
                    setSelectAmenties(
                      selectAmenties.filter((_) => _ != item.name)
                    );
                  }
                }}
              />
            );
          })}
        </Grid>
        <Button type="submit" className="btn btn-primary">
          {"Submit"}
        </Button>
      </form>
    </Box>
  );
};

export default PropertyAmenities;

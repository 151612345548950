import { Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

const RegulatoryInformation = ({ inlineStyle }) => {
  return (
    <Card sx={{ ml: '5px', padding: '16px' }}>
      <div style={inlineStyle}>
        <Typography level="h3" sx={{ fontWeight: "600" }}>
          {"Regulatory information"}
        </Typography>
        <Typography variant="string">
          {"Are you a professional host or a private host?"}
        </Typography>
        <div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Professional host"
            name="regulatory_information"
          >
            <FormControlLabel value="Professional host" control={<Radio />} label="Professional host" />
            <Typography variant="p"  sx={{ml: '30px', mb: 4}}>{"Providing accommodation is related to your trade, business, or profession."}</Typography>
            <FormControlLabel value="Private host" control={<Radio />} label="Private host" />
            <Typography variant="p" sx={{ml: '30px', mb: 4}}>{"Providing accommodation is not related to your trade, business, or profession."}</Typography>
          </RadioGroup>
        </FormControl>
        </div>
      </div>
    </Card>
  );
};

export default RegulatoryInformation;

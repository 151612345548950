const PartnerHeader = ({label}) => {
   return (<>
      <div class="dashboard-header">
            <h2>{label ? label : "Rates & availability"}</h2>
            <div class="user-info user-info-bg">
                <div>
                    <div class="input-group input-group-text-basic">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bi bi-search"></i>
                        </span>
                        <input type="text" class="form-control" placeholder="Search for something"
                            aria-label="Search" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <span type="button" class="me-3 position-relative">
                    <i class="bi bi-bell bi-bi-bell" style={{fontSize: "20px"}}></i>
                    <span
                        class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
                        style={{ height: "9x !important", width: "9px !important"}}>
                        <span class="visually-hidden">New alerts</span>
                    </span>
                </span>
                <img src="/images/dashboard/profileheader.svg" alt="Profile" class="rounded-circle"
                    width="40" />
                <span> </span>
            </div>
        </div>
   </>)
}

export default PartnerHeader;
import { Tab, Tabs } from "@mui/material";

const TabsComponent = ({ 
    value,
    handleChange,
    options,
    getValueItemFun
 }) => {
  return (<Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
    {options && options.length > 0 && options.map((item) => {
        return <Tab label={item.title} value={item.name} disabled={item.disabled} onClick={() => getValueItemFun(item)} />
    })}
  </Tabs>)
}
export default TabsComponent;
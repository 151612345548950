import { Button, Typography } from "@mui/material";
import {
  useRoomTypeQuery,
  useRoomClassQuery,
  useRoomSaveMutation,
  useGetPropertyDataQuery,
  useGetRoomByPropertyQuery,
  useDeleteRoomMutation,
} from "../../../Api/hotelApi";
import { useEffect, useState } from "react";
import StartWithBasicRoomComponent from "./startWithBasicRoom";
import RoomAmenties from "./roomAmenties";
import RoomPricingComponent from "./roomPricing";
import { useFormik } from "formik";
import {
  ratePerNightSchema,
  roomInsertStepOne,
  roomInsertStepTwo,
} from "../../../Schema/propertyRegistationSchema";
import { toast } from "react-toastify";
import InfoBox from "../../../Tools/RoomBox";

const RoomAndRates = ({ data, error, isLoading, refetch, setIsActive, roomData, roomRefetch }) => {
  // Destructure and alias variables to avoid conflicts

  const [editDataSave, setEditDataSave] = useState({});
  useEffect(() => {
    console.log("roomData", roomData);
  }, [roomData]);
  const {
    data: roomTypeData,
  } = useRoomTypeQuery();
  const {
    data: roomClassData,
  } = useRoomClassQuery();
  const [deleteRoom] = useDeleteRoomMutation();

  const [roomSave] = useRoomSaveMutation();
  const [steps, setSteps] = useState(1);
  const [stepShow, setStepShow] = useState(false);
  const [bathroomCount, setBathroomCount] = useState(data?.number_of_bathroom ? data.number_of_bathroom : 1);
  const [state, setState] = useState([]);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [selectAmenties, setSelectAmenties] = useState([]);

  const initialValues = {
    room_type: "",
    room_class: "",
    number_of_bedroom: "",
    number_of_living_space: "",
    smoking_area: "",
    number_of_rooms: "",
    bathroom_type: "",
    bath_or_shower: "",
    room_essential: "",
    room_size: "",
    provide_towels: "",
    room_view_option: "",
    room_views: "",
    room_layout: "",
    rate_per_night: "",
    room_occupancy: "",
    additional_room_charge: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      steps === 1
        ? roomInsertStepOne
        : steps === 2
        ? roomInsertStepTwo
        : ratePerNightSchema,
    onSubmit: async (values, { resetForm }) => {
      if (steps === 3) {
        if (editDataSave?.id) {
          values.room_id = editDataSave.id;
        }
        values.property_id = data.id;
        values.additional_room_charge = data.additional_room_charge
          ? data.additional_room_charge
          : state.additional_room_charge
          ? +state.additional_room_charge
          : 0;
        values.number_of_bathroom = Number(bathroomCount);
        values.room_essential = values.room_essential.toString();
        values.room_occupancy = values.room_occupancy
          ? +values.room_occupancy
          : 1;
        values.internet = selectAmenties.includes("internet") ? "yes" : "no";
        values.private_work_place = selectAmenties.includes(
          "private_work_place"
        )
          ? "yes"
          : "no";
        values.smart_key = selectAmenties.includes("smart_key") ? "yes" : "no";
        values.big_tv = selectAmenties.includes("big_tv") ? "yes" : "no";
        values.swimming_pool = selectAmenties.includes("swimming_pool")
          ? "yes"
          : "no";
        values.coffee_maker = selectAmenties.includes("coffee_maker")
          ? "yes"
          : "no";
        values.parking_available = selectAmenties.includes("parking_available")
          ? "yes"
          : "no";
        values.top_rated = selectAmenties.includes("top_rated") ? "yes" : "no";
        values.air_Conditioner = selectAmenties.includes("air_Conditioner")
          ? "yes"
          : "no";

        try {
          const response = await roomSave(values).unwrap();
          console.log("response---", response);
          if (response.status) {
            alert(response.message);
            // setIsActive("room_upload_photos");
            setEditDataSave(null);
            setSteps(1);
            roomRefetch();
            setStepShow(false);
            toast.success(response.message);
            // window.location.href = "/room_and_rates";
          }
        } catch (e) {
          console.log("error--->", e);
          toast.error(e.response.data.message);
          alert(e.response.data.message);
        }
      } else {
        setSteps(steps + 1);
      }
    },
  });

  useEffect(() => {
    console.log("formik.errors", formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    if (editDataSave) {
      const updatedValues = {
        room_type: editDataSave.roomTypeId || "",
        room_class: editDataSave.roomClassId || "",
        number_of_bedroom: editDataSave.number_of_bedroom || "",
        number_of_living_space: editDataSave.number_of_living_space || "",
        smoking_area: editDataSave.smoking_policy || "",
        number_of_rooms: editDataSave.number_of_room || "",
        bathroom_type: editDataSave.bathroom_type || "",
        bath_or_shower: editDataSave.room_have_bath_shower || "",
        room_essential: editDataSave.room_essential || "",
        provide_towels: editDataSave.provide_towels || "",
        room_views: editDataSave.room_view || "",
        room_size: editDataSave.room_size || "",
        room_layout: editDataSave.room_layout || "",
        rate_per_night: editDataSave.rate_per_night || "",
        room_occupancy: editDataSave.room_occupancy || "",
        additional_room_charge: +editDataSave.additional_room_charge || "",
      };

      Object.entries(editDataSave).forEach(([key, value]) => {
        if (value === "yes") {
          setSelectAmenties((prev) => [...prev, key]);
        }
      });

      // Update Formik and State
      formik.setValues(updatedValues);
      setState(updatedValues);
    }
  }, [editDataSave]); // Depend only on editDataSave

  const handleEditClick = (room) => {
    setEditDataSave(room);
    setStepShow(!stepShow);
  };

  const handleDeleteClick = async (room) => {
    try {
     const response = await deleteRoom(room.id).unwrap();
     if(response.status) {
       alert(response.message);
       toast.error(response.message);
       roomRefetch();
     }
    } catch (e) {
      console.log("delete error", e);
      toast.error(e.response.data.message);
    }
  }

  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <form onSubmit={formik.handleSubmit}>
              {!stepShow && (
                <>
                  {roomData &&
                    roomData.length > 0 &&
                    roomData.map((item) => {
                      return (
                        <InfoBox
                          heading={item?.roomTypeAssociation?.name}
                          description={[
                            item.roomClassAssociation?.name,
                            item.number_of_room,
                          ]}
                          editClick={() => handleEditClick(item)}
                          deleteClick={() => handleDeleteClick(item)}
                        />
                      );
                    })}
                  <Button className="btn btn-primary" onClick={() => {
                     setEditDataSave(null);
                     setStepShow(true);
                  }}>{"Add Room"}</Button>
                  <br></br>
                </>
              )}
              {stepShow && steps === 1 && (
                <StartWithBasicRoomComponent
                  roomTypedata={roomTypeData?.data ? roomTypeData.data : []}
                  roomClassData={roomClassData?.data ? roomClassData.data : []}
                  formik={formik}
                  initialValues={state}
                  state={state}
                  errors={formik.errors}
                  setState={setState}
                />
              )}
              {stepShow && steps === 2 && (
                <RoomAmenties
                  handleChange={handleChange}
                  setState={setState}
                  errors={formik.errors}
                  initialValues={state}
                  selectAmenties={selectAmenties}
                  setSelectAmenties={setSelectAmenties}
                  formik={formik}
                  bathroomCount={bathroomCount}
                  setBathroomCount={setBathroomCount}
                />
              )}
              {stepShow && steps === 3 && (
                <RoomPricingComponent
                  handleChange={handleChange}
                  errors={formik.errors}
                  formik={formik}
                />
              )}
              <br></br>
              {stepShow && steps !== 3 ? (
                <div style={{ justifyContent: "center" }} className="d-flex">
                  <Button
                    className="btn btn-primary"
                    onClick={() => setSteps(steps - 1)}
                    disabled={steps === 1}
                  >
                    {"Previous"}
                  </Button>
                  <Button className="btn btn-primary" type="submit">
                    {"Next"}
                  </Button>
                </div>
              ) : (
                <Button className="btn btn-primary" type="submit">
                  {"Submit"}
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomAndRates;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { adminRoute, mainRoute, partnerAuthRoute, partnerRoute } from "./route";
import BodyContainer from "../Tools/BodyContainer";
import Header from "../RegistrationHotel/Includes/header";
import Footer from "../RegistrationHotel/Includes/footer";
import RegistrationSidebar from "../RegistrationHotel/Includes/sidebar";
import PrivateRoute from "../PrivateRoute";
import PartnerHeader from "../Layout/Partner/header";
import PartnerSidebar from "../Layout/Partner/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setToken } from "../Slice/authSlice";

const Routing = ({ tokenn }) => {
  const BASE_PARTNER = '/partner';
  const BASE_ADMIN = '/admin';
  const BASE_MAIN = '/';
  console.log("tokeneeeeee", tokenn)

  return (
    <>
      <BrowserRouter>
        <Routes>
          {partnerRoute.length > 0 &&
            partnerRoute.map((_) => {
              return (
                <Route
                  exact
                  path={`${BASE_PARTNER}/${_.path}`}
                  element={
                    <BodyContainer
                      header={<PartnerHeader label={_.label} />}
                      sidebar={<PartnerSidebar />}
                      body={<PrivateRoute component={_.component} auth={tokenn} />}
                    />
                  }
                />
              );
            })}
          {partnerAuthRoute.length > 0 &&
            partnerAuthRoute.map((_) => {
              return (
                <Route
                  exact
                  path={`${BASE_PARTNER}/${_.path}`}
                  element={_.component}
                />
              );
          })}

        {mainRoute && mainRoute.length>0 && mainRoute.map((_)=>{
          return(<Route exact path={`/${_.path}`} element={_.component}/>);
        })}
        {adminRoute && adminRoute.length>0 && adminRoute.map((_) => {
          return (<Route exact path={`${BASE_ADMIN}/${_.path}`} element={_.component} />)
        })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;

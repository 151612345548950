import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../network';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    getAllPopularLocation: builder.query({
      query: () => ({
          url: `/api/v1/locations/popular`
      }),
      transformResponse: (response) => response.data
    }),
  }),
});

export const { useGetAllPopularLocationQuery } = locationApi;

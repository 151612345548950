import { useEffect, useState } from "react";
import { registrationHotelSidebarItem } from "../../../data/Hotel";
import TabsComponent from "../../../Tools/Tabs";
import Tabs from "../../../Tools/Tabs";
import BasicInformationPage from "../../../RegistrationHotel/Component/BasicInformation";
import PropertyAmenities from "../../../RegistrationHotel/Component/PropertyAmenties";
import RoomAndRates from "../../../RegistrationHotel/Component/RoomAndRates";
import PhotosPage from "../../../RegistrationHotel/Component/Photos";
import PropertyTaxes from "../../../RegistrationHotel/Component/Taxes";
import PointOfInterest from "../../../RegistrationHotel/Component/PointOfInterest";
import {
  useGetPropertyDataQuery,
  useGetRoomByPropertyQuery,
} from "../../../Api/hotelApi";

const RegisterProperty = () => {
  const [isActive, setIsActive] = useState("basic");
  const [stepsDisabled, setStepsDisabled] = useState("");
  const getValueItemFun = (item) => {
    setIsActive(item.name);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const { data, error, isLoading, refetch } = useGetPropertyDataQuery(user?.id);

  const {
    data: roomData,
    // isLoading: roomLoading,
    // error: roomError,
    refetch: roomRefetch,
  } = useGetRoomByPropertyQuery(data?.id);


  return (
    <>
      <div class="nk-content ">
        <div class="container-fluid">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <TabsComponent
                value={isActive}
                // handleChange={handleChange}
                getValueItemFun={getValueItemFun}
                options={registrationHotelSidebarItem}
              />
              {isActive === "basic" && (
                <BasicInformationPage
                  data={data}
                  setActiveTab={setIsActive}
                  refetch={refetch}
                />
              )}
              {isActive === "property_amenties" && (
                <PropertyAmenities
                  error={error}
                  isLoading={isLoading}
                  data={data}
                  refetch={refetch}
                  setIsActive={setIsActive}
                />
              )}
              {isActive === "room_and_rates" && (
                <RoomAndRates
                  error={error}
                  isLoading={isLoading}
                  data={data}
                  refetch={refetch}
                  setIsActive={setIsActive}
                  roomData={roomData}
                  roomFetch={roomRefetch}
                />
              )}
              {isActive === "room_upload_photos" && (
                <PhotosPage
                  propertyData={data}
                  refetch={refetch}
                  setIsActive={setIsActive}
                />
              )}
              {isActive === "property_taxes" && (
                <PropertyTaxes
                  error={error}
                  setIsActive={setIsActive}
                  refetch={refetch}
                  isLoading={isLoading}
                  data={data}
                />
              )}
              {isActive === "point_of_interest" && <PointOfInterest />}

              {/* {registrationHotelSidebarItem.map((i) => {
                 if(i.name===isActive) {
                    return <BasicInformationPage />
                 }
              })} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterProperty;

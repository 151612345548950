import DataTable from "react-data-table-component";
import { useGetAgentsQuery } from "../../../process/admin/agent/agentApi";
// import Checkbox from "@material-ui/core/Checkbox";

// const sortIcon = <ArrowDownward />;
const columns = [
	{
		name: 'Firstname',
		selector: row => row.firstname,
	},
	{
		name: 'Lastname',
		selector: row => row.lastname,
	},
    {
        name: "Email",
        selector: row => row.email
    }
];


const AgentList = () => {
  const { data, isLoading, isError }  = useGetAgentsQuery();
  console.log("data---->", data)
  return <DataTable pagination columns={columns} data={data} />;
};

export default AgentList;

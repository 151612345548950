import * as Yup from "yup";
export const basicInfoSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  city: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  street_address: Yup.string()
    .min(2, "Too Short!")
    .max(150, "Too Long!")
    .required("Required"),
  unit_number: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  state: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  pincode: Yup.string()
    .min(2, "Too Short!")
    .max(10, "Too Long!")
    .required("Required"),
  location: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  property_type: Yup.number().required("Required"),
  country: Yup.string().required("Required"),
  number_of_room: Yup.number("Please write in number")
    .required("Required"),
  legal_name: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  currency: Yup.string().required("Required"),
  channel_manager: Yup.string().required("Required"),
  part_of_chain: Yup.string().required("Required"),
});


// import * as Yup from "yup";

export const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First Name is required")
    .matches(/^[a-zA-Z]+$/, "First Name can only contain letters"),
  lastname: Yup.string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z]+$/, "Last Name can only contain letters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^[0-9]{10,15}$/,
      "Phone Number must be between 10 and 15 digits and only contain numbers"
    ),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
  password: Yup.string().required()
});

export const propertyUpdateAmentiesSchema = Yup.object().shape({
  check_in: Yup.string().required(),
  check_out: Yup.string().required(),
  lateCheckAvailable: Yup.string().required(),
  minimumAge: Yup.string().required(),
  pool_access: Yup.string().required(),
  accessibility_feature: Yup.string().required(),
  outdoor_recreation_areas: Yup.string().required(),
})

export const roomInsertStepOne = Yup.object().shape({
  room_type: Yup.string().required('Room Type is required'),
  room_class: Yup.string().required('Room Class is required'),
  number_of_bedroom: Yup.number().required("bedroom is required"),
  number_of_living_space: Yup.number().required("Living space is required"),
  smoking_area: Yup.string().required('Smoking area field is required'),
  number_of_rooms: Yup.number().required("Number of rooms is required")
});

export const roomInsertStepTwo = Yup.object().shape({
  bathroom_type: Yup.string().required('Bathroom Type is required'),
  bath_or_shower: Yup.string().required('Bath or shower is required'),
  room_essential: Yup.array().required("Room Essential is required"),
  number_of_living_space: Yup.number().required("Living space is required"),
  provide_towels: Yup.string().required('Provide towels is required'),
  room_view_option: Yup.string().required("Room view option is required"),
  room_size: Yup.number().required('Room Size is required'),
  room_layout: Yup.string().required('Room layout is required')
  // room_size_type: Yup.number().required('Room ')
})

export const ratePerNightSchema = Yup.object().shape({
  rate_per_night: Yup.number().required("Rate per night is required").min(250).max(300000)
});

export const taxesSchema = Yup.object().shape({
  legal_name: Yup.string().required("Legal Name is required"),
  property_register: Yup.string().required("Property register field is required"),
  vat_gst_id: Yup.string().required('Vat/Gst Id is required')
});

export const pointOfInterestSchema = Yup.object().shape({
  point_of_interest: Yup.string().required('This field is required')  
})